import React, {useCallback} from "react";
import {ModalWindow} from "components/Modals/common";
import {useDispatch, useSelector} from "react-redux";
import iconSuccess from "assets/img/icon-success.svg";
import iconWarning from "assets/img/icon-warning.svg";
import {isInvitesModalVisible, isInvitesModalSuccess} from "modules/selectors";
import {hideInvitesModal} from "modules/actions/modals";

export const InvitesModal: React.FC = () => {
	const dispatch = useDispatch();
	const isModalVisible = useSelector(isInvitesModalVisible);
	const isModalSuccess = useSelector(isInvitesModalSuccess);

	const handleClose = useCallback(() => {
		dispatch(hideInvitesModal());
	}, [dispatch]);

	if (!isModalVisible) {
		return null;
	}

	return (
		<ModalWindow
			title={isModalSuccess ? "Invites sent!" : "Error!"}
			message={
				isModalSuccess
					? "Your friends should receive their invites shortly."
					: "Sorry, something went wrong. Please try again later"
			}
			onClose={handleClose}
			iconSrc={isModalSuccess ? iconSuccess : iconWarning}
		/>
	);
};
