import {IUser} from "modules/reducers";
import {GAME_SEASON} from "modules/constants";

export function getIsUserRegisteredForGame(user: IUser | undefined, gameType: string, season?: number): boolean {
	if (!gameType || !user) {
		return false;
	}

	const game = user.registeredGames.find((e) => e.game === gameType);
	if (!game) {
		return false;
	}

	return Boolean(game.seasons.find((e) => {
		return e.season === (season || GAME_SEASON) && e.terms
	}))
}
