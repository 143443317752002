import React from "react";
import {useSelector} from "react-redux";
import {getMessageText} from "modules/selectors/messages";
import styled from "styled-components";
import {Container} from "components/Common";

const Wrapper = styled.section`
	padding: 15px;
	color: #1e1450;
	font-size: 14px;
	border-bottom: 1px solid #312daf;
	background: #bab9dc;
	width: 100%;
	text-align: center;
`;

export const GlobalMessage: React.FC = () => {
	const message = useSelector(getMessageText);

	if (!message) {
		return null;
	}

	return (
		<Wrapper>
			<Container>
				{/*eslint-disable-next-line react/no-danger */}
				<div dangerouslySetInnerHTML={{__html: message}} />
			</Container>
		</Wrapper>
	);
};
