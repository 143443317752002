import {call, delay, put, select} from "typed-redux-saga";
import {Api, ApiError, createConnextraScriptTag} from "modules/utils";
import {
    createLeague,
    createLeagueFailed,
    createLeagueSuccess,
    deleteRollbackLeagueRequest,
    deleteRollbackLeagueSuccess,
    fetchGlobalSwingsRankings,
    fetchLeagueUsersList,
    fetchLeagueUsersListFailed,
    fetchMoreLeagueUsersList,
    fetchLeagueUsersListSuccess,
    fetchLeagueMonthlyRankings,
    fetchLeagueRankings,
    fetchLeagueRankingsFailed,
    fetchLeagueRankingsSuccess,
    fetchMoreLeagueMonthlyRankings,
    fetchMoreLeagueRankings,
    fetchMoreRolexRankings,
    fetchMoreTopTenRankings,
    fetchRankingsMonthsSuccess,
    fetchRolexRankings,
    fetchTopTenRankings,
    inviteToLeague,
    inviteToLeagueFailed,
    inviteToLeagueSuccess,
    joinToLeague,
    joinToLeagueFailed,
    joinToLeagueSuccess,
    leaveLeague,
    leaveLeagueFailed,
    leaveLeagueSuccess,
    loadMoreLeaguesForJoin,
    rollbackAllLeaguesSuccess,
    rollbackLeagueRequest,
    rollbackLeagueSuccess,
    removeUserFromLeague,
    removeUserFromLeagueFailed,
    removeUserFromLeagueSuccess,
    searchLeaguesForJoin,
    showGlobalError,
    showLeague,
    showLeagueFailed,
    showLeaguesForJoin,
    showLeaguesForJoinFailed,
    showLeaguesForJoinSuccess,
    showLeagueSuccess,
    showMyLeagues,
    showMyLeaguesFailed,
    showMyLeaguesSuccess,
    updateLeague,
    updateLeagueFailed,
    updateLeagueSuccess,
} from "modules/actions/";
import {getLeagueRankings, getLeaguesForJoin, getRollbackSeasonOrUndefined, getLeagueUsersList, getUser} from "modules/selectors";

export const createLeagueSaga = function* ({payload}: ReturnType<typeof createLeague>) {
    try {
        const response = yield* call(Api.FantasyLeague.create, payload);

        const user = yield* select(getUser);

        createConnextraScriptTag(
            `https://zz.connextra.com/dcs/tagController/tag/a61b00e171af/regconfirm_league?AccountID=${
                user!.id
            }`
        );

        yield* put(createLeagueSuccess(response.success.league));
    } catch (err) {
        if (err instanceof ApiError) {
            yield* put(createLeagueFailed(err));
            yield* put(showGlobalError(err));
        }
    }
};

export const updateLeagueSaga = function* ({payload}: ReturnType<typeof updateLeague>) {
    try {
        const response = yield* call(Api.FantasyLeague.update, payload);
        yield* put(updateLeagueSuccess(response.success.league));
    } catch (err) {
        if (err instanceof ApiError) {
            yield* put(updateLeagueFailed(err));
            yield* put(showGlobalError(err));
        }
    }
};

export const sendLeagueInvitesSaga = function* ({payload}: ReturnType<typeof inviteToLeague>) {
    try {
        yield* call(Api.FantasyLeague.invite, payload);
        yield* put(inviteToLeagueSuccess());
    } catch (err) {
        if (err instanceof ApiError) {
            yield* put(inviteToLeagueFailed(err));
            yield* put(showGlobalError(err));
        }
    }
};

export const showMyLeaguesSaga = function* ({payload}: ReturnType<typeof showMyLeagues>) {
    try {
        const season = yield* select(getRollbackSeasonOrUndefined);
        const response = yield* call(Api.FantasyLeague.showMy, payload, season);
        yield* put(showMyLeaguesSuccess(response.success));
    } catch (err) {
        if (err instanceof ApiError) {
            yield* put(showMyLeaguesFailed(err));
            yield* put(showGlobalError(err));
        }
    }
};

export const showLeaguesForJoinSaga = function* ({payload}: ReturnType<typeof showLeaguesForJoin>) {
    try {
        const response = yield* call(Api.FantasyLeague.showForJoin, payload);
        yield* put(showLeaguesForJoinSuccess(response.success));
    } catch (err) {
        if (err instanceof ApiError) {
            yield* put(showLeaguesForJoinFailed(err));
            yield* put(showGlobalError(err));
        }
    }
};

export const searchLeaguesForJoinSaga = function* (
    action: ReturnType<typeof searchLeaguesForJoin>
) {
    yield* delay(150);
    yield* call(showLeaguesForJoinSaga, action);
};

export const loadMoreLeaguesForJoinSaga = function* ({
                                                         payload,
                                                     }: ReturnType<typeof loadMoreLeaguesForJoin>) {
    try {
        const leagues = yield* select(getLeaguesForJoin);
        const response = yield* call(Api.FantasyLeague.showForJoin, payload);

        yield* put(
            showLeaguesForJoinSuccess({
                leagues: [...leagues.list, ...response.success.leagues],
                nextPage: response.success.nextPage,
            })
        );
    } catch (err) {
        if (err instanceof ApiError) {
            yield* put(showLeaguesForJoinFailed(err));
            yield* put(showGlobalError(err));
        }
    }
};

export const joinToLeagueSaga = function* ({payload}: ReturnType<typeof joinToLeague>) {
    try {
        const response = yield* call(Api.FantasyLeague.joinToLeague, payload);
        const user = yield* select(getUser);

        createConnextraScriptTag(
            `https://zz.connextra.com/dcs/tagController/tag/a61b00e171af/regconfirm_league?AccountID=${
                user!.id
            }`
        );

        yield* put(joinToLeagueSuccess(response.success.league));
    } catch (err) {
        if (err instanceof ApiError) {
            yield* put(joinToLeagueFailed(err));
            yield* put(showGlobalError(err));
        }
    }
};

export const showLeagueSaga = function* ({payload}: ReturnType<typeof showLeague>) {
    try {
        const season = yield* select(getRollbackSeasonOrUndefined);
        const response = yield* call(Api.FantasyLeague.show, payload, season);
        yield* put(showLeagueSuccess(response.success));
    } catch (err) {
        if (err instanceof ApiError) {
            yield* put(showLeagueFailed(err));
            yield* put(showGlobalError(err));
        }
    }
};

export const leaveLeagueSaga = function* ({payload}: ReturnType<typeof leaveLeague>) {
    try {
        yield* call(Api.FantasyLeague.leave, payload);
        yield* put(leaveLeagueSuccess(payload.id));
    } catch (err) {
        if (err instanceof ApiError) {
            yield* put(leaveLeagueFailed(err));
            yield* put(showGlobalError(err));
        }
    }
};

export const leagueRankingsSaga = function* ({payload}: ReturnType<typeof fetchLeagueRankings>) {
    try {
        const season = yield* select(getRollbackSeasonOrUndefined);
        const result = yield* call(Api.FantasyLeague.rankings, payload, season);
        yield* put(fetchLeagueRankingsSuccess(result.success));
    } catch (err) {
        if (err instanceof ApiError) {
            yield* put(fetchLeagueRankingsFailed(err));
            yield* put(showGlobalError(err));
        }
    }
};

export const loadMoreLeagueRankingsSaga = function* ({
                                                         payload,
                                                     }: ReturnType<typeof fetchMoreLeagueRankings>) {
    try {
        const rankings = yield* select(getLeagueRankings);
        const season = yield* select(getRollbackSeasonOrUndefined);
        const result = yield* call(Api.FantasyLeague.rankings, payload, season);
        yield* put(
            fetchLeagueRankingsSuccess({
                ranking: [...rankings.ranking, ...result.success.ranking],
                next_page: result.success.next_page,
                user: result.success.user
            })
        );
    } catch (err) {
        if (err instanceof ApiError) {
            yield* put(fetchLeagueRankingsFailed(err));
            yield* put(showGlobalError(err));
        }
    }
};

export const leagueMonthlyRankingsSaga = function* ({
                                                        payload,
                                                    }: ReturnType<typeof fetchLeagueMonthlyRankings>) {
    try {
        const season = yield* select(getRollbackSeasonOrUndefined);
        const result = yield* call(Api.FantasyLeague.monthRankings, payload, season);
        yield* put(fetchLeagueRankingsSuccess(result.success));
    } catch (err) {
        if (err instanceof ApiError) {
            yield* put(fetchLeagueRankingsFailed(err));
            yield* put(showGlobalError(err));
        }
    }
};

export const loadMoreLeagueMonthlyRankingsSaga = function* ({
                                                                payload,
                                                            }: ReturnType<typeof fetchMoreLeagueMonthlyRankings>) {
    try {
        const rankings = yield* select(getLeagueRankings);
        const season = yield* select(getRollbackSeasonOrUndefined);
        const result = yield* call(Api.FantasyLeague.monthRankings, payload, season);
        yield* put(
            fetchLeagueRankingsSuccess({
                ranking: [...rankings.ranking, ...result.success.ranking],
                next_page: result.success.next_page,
                user: result.success.user
            })
        );
    } catch (err) {
        if (err instanceof ApiError) {
            yield* put(fetchLeagueRankingsFailed(err));
            yield* put(showGlobalError(err));
        }
    }
};

export const rolexRankingsSaga = function* ({payload}: ReturnType<typeof fetchRolexRankings>) {
    try {
        const season = yield* select(getRollbackSeasonOrUndefined);
        const result = yield* call(Api.FantasyLeague.rolexRankings, payload, season);
        yield* put(fetchLeagueRankingsSuccess(result.success));
    } catch (err) {
        if (err instanceof ApiError) {
            yield* put(fetchLeagueRankingsFailed(err));
            yield* put(showGlobalError(err));
        }
    }
};

export const loadMoreRolexRankingsSaga = function* ({
                                                        payload,
                                                    }: ReturnType<typeof fetchMoreRolexRankings>) {
    try {
        const rankings = yield* select(getLeagueRankings);
        const season = yield* select(getRollbackSeasonOrUndefined);
        const result = yield* call(Api.FantasyLeague.rolexRankings, payload, season);
        yield* put(
            fetchLeagueRankingsSuccess({
                ranking: [...rankings.ranking, ...result.success.ranking],
                next_page: result.success.next_page,
                user: result.success.user
            })
        );
    } catch (err) {
        if (err instanceof ApiError) {
            yield* put(fetchLeagueRankingsFailed(err));
            yield* put(showGlobalError(err));
        }
    }
};

export const topTenRankingsSaga = function* ({payload}: ReturnType<typeof fetchTopTenRankings>) {
    try {
        const season = yield* select(getRollbackSeasonOrUndefined);
        const result = yield* call(Api.FantasyLeague.topTenRankings, payload, season);
        yield* put(fetchLeagueRankingsSuccess(result.success));
    } catch (err) {
        if (err instanceof ApiError) {
            yield* put(fetchLeagueRankingsFailed(err));
            yield* put(showGlobalError(err));
        }
    }
};

export const loadMoreTopTenRankingsSaga = function* ({
                                                         payload,
                                                     }: ReturnType<typeof fetchMoreTopTenRankings>) {
    try {
        const rankings = yield* select(getLeagueRankings);
        const season = yield* select(getRollbackSeasonOrUndefined);
        const result = yield* call(Api.FantasyLeague.topTenRankings, payload, season);
        yield* put(
            fetchLeagueRankingsSuccess({
                ranking: [...rankings.ranking, ...result.success.ranking],
                next_page: result.success.next_page,
                user: result.success.user
            })
        );
    } catch (err) {
        if (err instanceof ApiError) {
            yield* put(fetchLeagueRankingsFailed(err));
            yield* put(showGlobalError(err));
        }
    }
};

export const globalSwingsRankingsSaga = function* ({payload}: ReturnType<typeof fetchGlobalSwingsRankings>) {
    try {
        const season = yield* select(getRollbackSeasonOrUndefined);
        const result = yield* call(Api.FantasyLeague.globalSwingsRankings, payload, season);
        yield* put(fetchLeagueRankingsSuccess(result.success));
    } catch (err) {
        if (err instanceof ApiError) {
            yield* put(fetchLeagueRankingsFailed(err));
            yield* put(showGlobalError(err));
        }
    }
};

export const loadMoreGlobalSwingsRankingsSaga = function* ({
                                                               payload,
                                                           }: ReturnType<typeof fetchGlobalSwingsRankings>) {
    try {
        const rankings = yield* select(getLeagueRankings);
        const season = yield* select(getRollbackSeasonOrUndefined);
        const result = yield* call(Api.FantasyLeague.globalSwingsRankings, payload, season);
        yield* put(
            fetchLeagueRankingsSuccess({
                ranking: [...rankings.ranking, ...result.success.ranking],
                next_page: result.success.next_page,
                user: result.success.user
            })
        );
    } catch (err) {
        if (err instanceof ApiError) {
            yield* put(fetchLeagueRankingsFailed(err));
            yield* put(showGlobalError(err));
        }
    }
};

export const rollbackAllLeaguesSaga = function* () {
    try {
        yield* call(Api.Rollover.leagueRestoreAll);
        yield* put(rollbackAllLeaguesSuccess());
        yield* put(showMyLeagues({}));
    } catch (err) {
        if (err instanceof ApiError) {
            yield* put(showGlobalError(err));
        }
    }
};

export const rollbackLeagueSaga = function* ({payload}: ReturnType<typeof rollbackLeagueRequest>) {
    try {
        yield* call(Api.Rollover.leagueRestore, payload);
        yield* put(rollbackLeagueSuccess(payload));
        yield* put(showMyLeagues({}));
    } catch (err) {
        if (err instanceof ApiError) {
            yield* put(showGlobalError(err));
        }
    }
};

export const deleteRollbackLeagueSaga = function* ({payload}: ReturnType<typeof deleteRollbackLeagueRequest>) {
    try {
        yield* call(Api.Rollover.leagueDelete, payload);
        yield* put(deleteRollbackLeagueSuccess(payload));
    } catch (err) {
        if (err instanceof ApiError) {
            yield* put(showGlobalError(err));
        }
    }
};

export const fetchRankingsMonthsSaga = function* () {
    try {
        const season = yield* select(getRollbackSeasonOrUndefined);
        const response = yield* call(Api.FantasyLeague.rankingsMonths, season);
        yield* put(fetchRankingsMonthsSuccess(response.success.months));
    } catch (err) {
        if (err instanceof ApiError) {
            yield* put(showGlobalError(err));
        }
    }
}


export const leagueUsersListSaga = function* ({payload}: ReturnType<typeof fetchLeagueUsersList>) {
    try {
        const result = yield* call(Api.FantasyLeague.show_joined, payload);
        yield* put(fetchLeagueUsersListSuccess(result.success.joinedUsers));
    } catch (err) {
        if (err instanceof ApiError) {
            yield* put(fetchLeagueUsersListFailed(err));
            yield* put(showGlobalError(err));
        }
    }
};

export const loadMoreLeagueUsersListSaga = function* ({
                                                          payload,
                                                      }: ReturnType<typeof fetchMoreLeagueUsersList>) {
    try {
        const rankings = yield* select(getLeagueUsersList);
        const result = yield* call(Api.FantasyLeague.show_joined, payload);
        yield* put(
            fetchLeagueUsersListSuccess({
                users: [...rankings.users, ...result.success.joinedUsers.users],
                nextPage: result.success.joinedUsers.nextPage,
            })
        );
    } catch (err) {
        if (err instanceof ApiError) {
            yield* put(fetchLeagueUsersListFailed(err));
            yield* put(showGlobalError(err));
        }
    }
};

export const removeUserFromLeagueSaga = function* ({
	payload,
}: ReturnType<typeof removeUserFromLeague>) {
	try {
		yield* call(Api.FantasyLeague.delete_user, payload);
		yield* put(removeUserFromLeagueSuccess(payload.userID));
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(removeUserFromLeagueFailed(err));
			yield* put(showGlobalError(err));
		}
	}
};
