import {ContextType, RefObject, useCallback, useContext, useEffect, useState} from "react";
import type {Blocker, History, Transition} from "history";
import {
	Navigator as BaseNavigator,
	UNSAFE_NavigationContext as NavigationContext,
	useLocation,
	useNavigate,
} from "react-router-dom";

interface INavigator extends BaseNavigator {
	block: History["block"];
}

type NavigationContextWithBlock = ContextType<typeof NavigationContext> & {navigator: INavigator};

/**
 * @source https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874
 */
const useBlocker = (blocker: Blocker, when = true) => {
	const {navigator} = useContext(NavigationContext) as NavigationContextWithBlock;

	useEffect(() => {
		if (!when) {
			return;
		}

		const unblock = navigator.block((tx: Transition) => {
			const autoUnblockingTx = {
				...tx,
				retry() {
					// Automatically unblock the transition so it can play all the way
					// through before retrying it. TODO: Figure out how to re-enable
					// this block if the transition is cancelled for some reason.
					unblock();
					tx.retry();
				},
			};

			blocker(autoUnblockingTx);
		});

		return unblock;
	}, [navigator, blocker, when]);
};

export const useBlockNavigation = (when: boolean) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [showPrompt, setShowPrompt] = useState(false);
	const [lastLocation, setLastLocation] = useState<Transition | null>(null);
	const [confirmedNavigation, setConfirmedNavigation] = useState(false);

	const cancelNavigation = useCallback(() => {
		setShowPrompt(false);
	}, []);

	const handleBlockedNavigation: Blocker = useCallback(
		(nextLocation) => {
			const isRouteChanged = nextLocation.location.pathname !== location.pathname;

			if (!confirmedNavigation && isRouteChanged) {
				setShowPrompt(true);
				setLastLocation(nextLocation);

				return false;
			}

			return true;
		},
		[confirmedNavigation, location.pathname]
	);

	const confirmNavigation = useCallback(() => {
		setShowPrompt(false);
		setConfirmedNavigation(true);
	}, []);

	useEffect(() => {
		if (confirmedNavigation && lastLocation) {
			navigate(lastLocation.location.pathname);
		}
	}, [confirmedNavigation, lastLocation, navigate]);

	useBlocker(handleBlockedNavigation, when);

	return [showPrompt, confirmNavigation, cancelNavigation] as const;
};

export const useURLQuery = () => new URLSearchParams(useLocation().search);

export const useScrollTo =
	({current}: RefObject<HTMLElement>) =>
	() => {
		if (current) {
			window.scrollTo({
				top: current.getBoundingClientRect().top + window.scrollY,
				behavior: "smooth",
			});
		}
	};
