import {HTTPClient as HTTPClientCore, IRequestConfig} from "@fanhubmedia/fe-common-utils";
import axios, {AxiosError} from "axios";
import {isObject, set} from "lodash";
import ApiError from "./ApiError";
import {API_URL, GAME_TYPE, JSON_URL} from "modules/constants";
import {IApiResponse} from "modules/types";
import {ISwing, IUser} from "modules/reducers";
import {
    IAcceptTermsPayload,
    IContactPayload,
    ICountry,
    ICreateLeaguePayload,
    IEvent,
    IFaqItem,
    IHelpResponse,
    IInviteToLeaguePayload,
    ILeague,
    ILeagueMonthlyRankingsPayload,
    ILeagueRankingsPayload,
    ILeagueRankingsResult,
    ILeagueUserListPayload,
    ILeagueUsersListResult,
    IMessageResponse,
    IMyLeague,
    IPlayer,
    IPlayerStatsByEvent,
    IRankingsMonth,
    IRequestedRivalTeamPayload,
    IRequestResetPasswordPayload,
    IResetPasswordPayload,
    IRolexRankingsPayload,
    IRollbackLeague,
    ISeasonStats,
    IShowLeaguePayload,
    IShowLeagueResult,
    IShowLeaguesPayload,
    ITeam,
    ITopTenRankingsPayload,
    IUpdateLeaguePayload,
    IUserLoginPayload,
    IUserRegisterPayload,
    IUserUpdatePayload,
    IRemoveUserFromLeaguePayload,
} from "modules/actions";
import {CANCEL} from "redux-saga";

class HTTPClient extends HTTPClientCore {
    /**
     * Overridden method adds CancelToken symbol, that allow redux-saga'
     * "takeLatest" function to cancel any requests automatically.
     * http://fe-common-utils.s3-website-eu-west-1.amazonaws.com/classes/httpclient.html
     */
    public makeRequest<T>(config: IRequestConfig): Promise<T> {
        const source = axios.CancelToken.source();

        const request = super.makeRequest<T>({
            ...config,
            cancelToken: source.token,
        });

        return set<Promise<T>>(request, CANCEL, () => source.cancel());
    }
}

const onCatchNetworkError = ({response, message = "Network error"}: AxiosError<ApiError>) => {
    const data = response?.data;
    const status = response?.status;
    const error = isObject(data)
        ? data
        : {
            errors: [new ApiError(message, status)],
        };

    return Promise.reject(error).catch((err) => ApiError.CHECK(err as IApiResponse, status));
};

const APIClient = new HTTPClient({
    baseURL: API_URL,
    withCredentials: true,
    onCatchNetworkError,
});

const JSONClient = new HTTPClient({
    baseURL: JSON_URL,
});

type TUserResponse = IApiResponse<{ user: IUser }>;
type TShowMyTeamResponse = IApiResponse<{ team: ITeam }>;
type TCreateLeagueResponse = IApiResponse<{ league: ILeague }>;
type TJoinLeagueResponse = IApiResponse<{ league: IMyLeague }>;
type TPlayerStatsByEventResponse = Record<number, IPlayerStatsByEvent>;
type TShowMyLeaguesResponse = IApiResponse<{
    leagues: IMyLeague[];
    inactiveLeagues: IRollbackLeague[];
    nextPage: boolean;
}>;
type TLeagueRankingsResult = IApiResponse<ILeagueRankingsResult>;
type TLeagueRankingsMonthesResponse = IApiResponse<{ months: IRankingsMonth[] }>;
type TLeagueUsersListResult = IApiResponse<{ joinedUsers: ILeagueUsersListResult }>;

interface IAutofillTeamPayload {
    lineup?: number[];
    captain?: number;
}

interface ISaveTeamPayload extends IAutofillTeamPayload {
    name?: string;
}

export const Api = {
    JSON: {
        countries: () => JSONClient.get<ICountry[]>("countries.json"),
        events: (season = "") => {
            const URL = season ? `${season}/events.json` : "events.json";
            return JSONClient.get<IEvent[]>(URL)
        },
        swings: () => JSONClient.get<ISwing[]>("swing.json"),
        players: (eventID: number, season = "") => {
            const URL = season ? `2023/players/${eventID}.json` : `players/${eventID}.json`;
            return JSONClient.get<IPlayer[]>(URL)
        },
        playerStats: (playerID: number) =>
            JSONClient.get<TPlayerStatsByEventResponse>(`players/stats/${playerID}.json`),
        checksums: () => JSONClient.get<Record<string, string>>("checksums.json"),
        gameRules: () => JSONClient.get<IHelpResponse>("game_rules.json"),
        faq: () => JSONClient.get<IFaqItem[]>("faq.json"),
        terms: () => JSONClient.get<IHelpResponse>("terms.json"),
        schedule: () => JSONClient.get<IHelpResponse>("schedule.json"),
        prizes: () => JSONClient.get<IHelpResponse>("prizes.json"),
        privacy: () => JSONClient.get<IHelpResponse>("privacy.json"),
        message: () => JSONClient.get<IMessageResponse>("messages.json"),
        seasonStats: () => JSONClient.get<Record<number, ISeasonStats>>("season_stats.json"),
    },
    Auth: {
        login: (params: Pick<IUserLoginPayload, "uid" | "uidSignature" | "signatureTimestamp">) =>
            APIClient.post<TUserResponse>("auth/login/sso", params),
        register: (params: IUserRegisterPayload & IUserLoginPayload) =>
            APIClient.post<TUserResponse>("auth/register/sso", {
                ...params,
                game: GAME_TYPE,
            }),
        registerForGame: (params: IAcceptTermsPayload) =>
            APIClient.post<TUserResponse>("auth/game/register", {
                ...params,
                game: GAME_TYPE,
            }),
        logout: () => APIClient.post<IApiResponse>("auth/logout"),
        deactivate: () => APIClient.post<IApiResponse>("auth/deactivate_account"),
        requestPasswordReset: (params: IRequestResetPasswordPayload) =>
            APIClient.post<TUserResponse>("auth/password_reset/request ", params),
        passwordReset: (params: IResetPasswordPayload) =>
            APIClient.post<TUserResponse>("auth/password_reset ", params),
    },
    FantasyTeam: {
        showMy: (eventID: number, season: string = "") => {
            const URL = `SEASON/fantasy_team/show_my/${eventID}`.replace("SEASON", season)
            return APIClient.get<TShowMyTeamResponse>(URL);
        },
        showRivalTeam: ({eventID, userID}: IRequestedRivalTeamPayload, season: string = "") => {
            const URL = `SEASON/fantasy_team/show_rival_team/${userID}/${eventID}`.replace("SEASON", season)
            return APIClient.get<TShowMyTeamResponse>(URL)
        },
        save: (params: ISaveTeamPayload) =>
            APIClient.post<TShowMyTeamResponse>("fantasy_team/save", params),
        autofill: (params: IAutofillTeamPayload) =>
            APIClient.post<TShowMyTeamResponse>("fantasy_team/autofill", params),
        clear_withdrawn: () => APIClient.post<void>("fantasy_team/clear_withdrawn "),
    },
    FantasyLeague: {
        create: (params: ICreateLeaguePayload) =>
            APIClient.post<TCreateLeagueResponse>("fantasy/league", params),
        update: ({id, ...params}: IUpdateLeaguePayload) =>
            APIClient.post<TCreateLeagueResponse>(`fantasy/league/${id}`, params),
        showMy: (params: IShowLeaguesPayload, season = "") => {
            const URL = "SEASON/fantasy/league/show-my".replace("SEASON", season)
            return APIClient.get<TShowMyLeaguesResponse>(URL, params)
        },
        show: ({id}: IShowLeaguePayload, season: string = "") => {
            const URL = `SEASON/fantasy/league/${id}`.replace("SEASON", season)
            return APIClient.get<IApiResponse<IShowLeagueResult>>(URL)
        },
        leave: ({id}: IShowLeaguePayload) =>
            APIClient.post<IApiResponse>(`fantasy/league/${id}/leave`),
        showForJoin: (params: IShowLeaguesPayload) =>
            APIClient.get<TShowMyLeaguesResponse>("fantasy/league/show-for-join", params),
        invite: ({id, ...params}: IInviteToLeaguePayload) =>
            APIClient.post<IApiResponse>(`fantasy/league/${id}/invite`, params),
        joinToLeague: (code: string) =>
            APIClient.post<TJoinLeagueResponse>(`fantasy/league/${code}/join`),
        rankings: ({leagueID, eventID, ...params}: ILeagueRankingsPayload, season = "") => {
            const eventStr = eventID ? `/${eventID}` : "";
            const URL = `SEASON/fantasy/rankings/${leagueID}/rankings${eventStr}`.replace("SEASON", season)

            return APIClient.get<TLeagueRankingsResult>(URL, params);
        },
        monthRankings: ({leagueID, month, year, ...params}: ILeagueMonthlyRankingsPayload, season = "") => {
            const URL = `SEASON/fantasy/rankings/${leagueID}/month_rankings/${month}/YEAR`
                .replace("SEASON", season)
                .replace("YEAR", String(year || ""))

            return APIClient.get<TLeagueRankingsResult>(URL, params);
        },
        show_joined: ({leagueID, ...params}: ILeagueUserListPayload) => {
            return APIClient.get<TLeagueUsersListResult>(
                `fantasy/league/${leagueID}/user/show-joined`,
                params
            );
        },
        delete_user: ({leagueID, userID}: IRemoveUserFromLeaguePayload) => {
            return APIClient.post<void>(`fantasy/league/${leagueID}/user/${userID}`);
        },
        rolexRankings: ({leagueID, ...params}: IRolexRankingsPayload, season = "") => {
            const URL = `SEASON/fantasy/rankings/${leagueID}/rolex_rankings`.replace("SEASON", season);
            return APIClient.get<TLeagueRankingsResult>(URL, params);
        },
        topTenRankings: (params: ITopTenRankingsPayload, season = "") => {
            const URL = `SEASON/fantasy/rankings/top10_rankings`.replace("SEASON", season);
            return APIClient.get<TLeagueRankingsResult>(URL, params);
        },
        globalSwingsRankings: (params: ILeagueRankingsPayload, season = "") => {
            const {swingId, leagueID, ...rest} = params
            const URL = `SEASON/fantasy/rankings/${leagueID}/swing_rankings/${swingId || 0}`.replace("SEASON", season)

            return APIClient.get<TLeagueRankingsResult>(URL, rest)
        },
        rankingsMonths: (season = "") => {
            const URL = `SEASON/fantasy/rankings/month_list`.replace("SEASON", season)
            return APIClient.get<TLeagueRankingsMonthesResponse>(URL)
        }
    },
    Rollover: {
        leagueRestore: (leagueId: number) =>
            APIClient.post<void>(`fantasy/league/${leagueId}/restore`),
        leagueRestoreAll: () =>
            APIClient.post<void>(`fantasy/league/restore-all`),
        leagueDelete: (leagueId: number) =>
            APIClient.post<void>(`fantasy/league/${leagueId}/delete-last-season`)
    },
    User: {
        user: () => APIClient.get<TUserResponse>("user"),
        update: (params: IUserUpdatePayload) =>
            APIClient.post<TUserResponse>("user/update", params),
    },
    Common: {
        contact: (params: IContactPayload) => APIClient.post<IApiResponse>("contact", params),
    },
};

export * from "./ApiError";

export default Api;
