import {createAction} from "redux-act";
import {LeaguePrivacy, LeagueStatus, LeagueType} from "modules/enums";
import {IError} from "modules/types";

export interface ICreateLeaguePayload {
	name: string;
	eventId?: number;
	privacy: LeaguePrivacy;
	description?: string;
}

export interface IRollbackLeague {
	id: number;
	name: string;
	userId: number;
	code: string;
	privacy: LeaguePrivacy;
}

export interface ILeague extends IRollbackLeague{
	status: LeagueStatus;
	class: LeagueType;
	eventId: number;
	description: string;
	numberJoinedUsers: number;
	chairman?: string | null;
}

export const createLeague = createAction<ICreateLeaguePayload>();
export const createLeagueSuccess = createAction<ILeague>();
export const createLeagueFailed = createAction<IError>();
export const clearTempLeague = createAction();

export type IUpdateLeaguePayload = Partial<ICreateLeaguePayload> & {
	id: number;
};

export const updateLeague = createAction<IUpdateLeaguePayload>();
export const updateLeagueSuccess = createAction<ILeague>();
export const updateLeagueFailed = createAction<IError>();
export const resetUpdateLeagueRequestState = createAction();

export interface IInviteToLeaguePayload {
	id: number;
	invites: {
		name: string;
		email: string;
	}[];
}

export const inviteToLeague = createAction<IInviteToLeaguePayload>();
export const inviteToLeagueSuccess = createAction();
export const inviteToLeagueFailed = createAction<IError>();
export const clearLeagueInvitesState = createAction();

export interface IShowLeaguesPayload {
	search?: string;
	eventId?: number;
	page?: number;
	limit?: number;
}

export interface IMyLeague extends ILeague {
	rank: number | null;
	last_event_rank: number | null;
	is_sponsored?: boolean;
    is_joined?: boolean;
}

export const showMyLeagues = createAction<IShowLeaguesPayload>();
export const showMyLeaguesSuccess = createAction<{
	leagues: IMyLeague[];
	inactiveLeagues: IRollbackLeague[];
	nextPage: boolean;
}>();
export const showMyLeaguesFailed = createAction<IError>();

export const showLeaguesForJoin = createAction<IShowLeaguesPayload>();
export const showLeaguesForJoinSuccess = createAction<{
	leagues: IMyLeague[];
	nextPage: boolean;
}>();
export const showLeaguesForJoinFailed = createAction<IError>();

export const loadMoreLeaguesForJoin = createAction<IShowLeaguesPayload>();
export const searchLeaguesForJoin = createAction<Pick<IShowLeaguesPayload, "search">>();

export const joinToLeague = createAction<string>();
export const joinToLeagueSuccess = createAction<IMyLeague>();
export const joinToLeagueFailed = createAction<IError>();
export const clearLeaguesForJoin = createAction();

export interface IShowLeaguePayload {
	id: number;
}

export interface IShowLeagueResult {
	league: IMyLeague;
	isJoined: boolean;
}

export const showLeague = createAction<IShowLeaguePayload>();
export const showLeagueSuccess = createAction<IShowLeagueResult>();
export const showLeagueFailed = createAction<IError>();

export const leaveLeague = createAction<IShowLeaguePayload>();
export const leaveLeagueSuccess = createAction<number>();
export const leaveLeagueFailed = createAction<IError>();
export const resetLeaveLeagueRequestState = createAction();

export interface ILeagueUserListPayload {
	leagueID: number;
	eventID?: number;
	page: number;
	/**
	 * Default value: 20
	 */
	limit?: number;
	swingId?: number;
}

export type ISwingRankingsPayload = Omit<ILeagueRankingsPayload, "leagueID">;

export interface ILeagueRankingsPayload extends ILeagueUserListPayload {
	eventID?: number;
}

export interface ILeagueRank {
	rank: number;
	user_id: number;
	team_name: string;
	name: string;
	points: number;
	joining_event?: string;
}

export interface ILeagueRankingsResult {
	ranking: ILeagueRank[];
	user: ILeagueRank | null;
	next_page: number | null;
}

export const fetchLeagueRankings = createAction<ILeagueRankingsPayload>();
export const fetchMoreLeagueRankings = createAction<ILeagueRankingsPayload>();
export const fetchLeagueRankingsSuccess = createAction<ILeagueRankingsResult>();
export const fetchLeagueRankingsFailed = createAction<IError>();
export const clearLeagueRankings = createAction();

export interface ILeagueUser {
	id: number;
	username: string;
	name: string;
}

export interface ILeagueUsersListResult {
	users: ILeagueUser[];
	nextPage: boolean;
}

export const fetchLeagueUsersList = createAction<ILeagueUserListPayload>();
export const fetchMoreLeagueUsersList = createAction<ILeagueUserListPayload>();
export const fetchLeagueUsersListSuccess = createAction<ILeagueUsersListResult>();
export const fetchLeagueUsersListFailed = createAction<IError>();
export const clearLeagueUsersList = createAction();

export interface IRemoveUserFromLeaguePayload {
	leagueID: number;
	userID: number;
}

export const removeUserFromLeague = createAction<IRemoveUserFromLeaguePayload>();
export const removeUserFromLeagueSuccess = createAction<number>();
export const removeUserFromLeagueFailed = createAction<IError>();

export interface ILeagueMonthlyRankingsPayload extends Omit<ILeagueRankingsPayload, "eventID"> {
	month: number;
	year?: number;
}

export const fetchLeagueMonthlyRankings = createAction<ILeagueMonthlyRankingsPayload>();
export const fetchMoreLeagueMonthlyRankings = createAction<ILeagueMonthlyRankingsPayload>();

export type IRolexRankingsPayload = Omit<ILeagueRankingsPayload, "eventID">;

export const fetchRolexRankings = createAction<IRolexRankingsPayload>();
export const fetchMoreRolexRankings = createAction<IRolexRankingsPayload>();

export type ITopTenRankingsPayload = Pick<ILeagueRankingsPayload, "page" | "limit">;

export const fetchTopTenRankings = createAction<ITopTenRankingsPayload>();
export const fetchMoreTopTenRankings = createAction<ITopTenRankingsPayload>();


export const fetchGlobalSwingsRankings = createAction<ILeagueRankingsPayload>();
export const fetchMoreGlobalSwingsRankings = createAction<ILeagueRankingsPayload>();

export const rollbackAllLeaguesRequest = createAction();
export const rollbackAllLeaguesSuccess = createAction();

export const rollbackLeagueRequest = createAction<number>();
export const rollbackLeagueSuccess = createAction<number>();

export const deleteRollbackLeagueRequest = createAction<number>();
export const deleteRollbackLeagueSuccess = createAction<number>();

export interface IRankingsMonth {
	month: number;
	year: number;
	name?: string;
	customId?: string;
}

export type IEventForLeaderboard = number | string | undefined;

export const fetchRankingsMonthsRequest = createAction();
export const clearRankingsMonths = createAction();
export const fetchRankingsMonthsSuccess = createAction<IRankingsMonth[]>();

export const selectEventForLeaderboardRival = createAction<IEventForLeaderboard>();
export const clearEventForLeaderboardRival = createAction();