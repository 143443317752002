import {IStore} from "modules/types/store";
import {createSelector} from "reselect";
import {GAME_SEASON_STRING} from "modules/constants";

export const getState = ({season}: IStore) => season;

export const getSeason = createSelector(getState, (state) => state.selectedSeason);
export const getIsCurrentSeason = createSelector(getSeason, (season) => season === GAME_SEASON_STRING);
export const getRollbackSeasonOrUndefined = createSelector(getSeason, getIsCurrentSeason,
	(season, isCurrentSeason) => isCurrentSeason ? undefined : season);
