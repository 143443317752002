import {createAction} from "redux-act";

export interface ICountry {
	name: string;
	code: string;
}

export const requestCountriesJSON = createAction();
export const requestCountriesJSONSuccess = createAction<ICountry[]>();
export const requestCountriesJSONFailed = createAction();
