import React from "react";
import {Container, ExternalLink, GameLogoLink, InternalLink} from "components/Common";
import styled from "styled-components";
import logoDark from "assets/img/fantlogo-dark.svg";
import logoLight from "assets/img/fantlogo-light.svg";
import geniusLogoDark from "assets/img/genius-logo-dark.svg";
import geniusLogoLight from "assets/img/genius-logo-light.svg";
import {IS_SECRET_PASSED} from "modules";

const Wrapper = styled.footer`
	background: #fff;
	color: #0f0f19;
	font-weight: 300;
	font-size: 12px;
	line-height: 16px;
	width: 100%;
	padding: 25px 0;
`;

const WrapperDark = styled(Wrapper)`
	color: #fff;
	background: linear-gradient(to right, #211439, #251b53 60%, #383582);
`;

const TopBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;

	@media (max-width: 640px) {
		flex-flow: column;
		gap: 15px;
		margin-bottom: 20px;
	}
`;

const MiddleBox = styled.div`
	width: 80%;

	p {
		margin-bottom: 20px;
	}

	@media (max-width: 640px) {
		width: 100%;
		text-align: center;
	}
`;

const BottomBox = styled.div`
	position: relative;
	display: flex;

	@media (max-width: 640px) {
		flex-flow: column;
		align-items: center;
	}
`;

const Nav = styled.nav`
	display: flex;
	align-items: flex-start;
	margin-bottom: 20px;

	@media (max-width: 640px) {
		align-items: center;
		margin-top: 10px;
		display: contents;
	}
`;

const NavLink = styled(InternalLink)`
	display: inline-block;
	margin-left: 15px;
	color: currentColor;
	margin-bottom: 5px;
	background-size: 0 1px;

	&:hover,
	&:focus {
		background-size: 100% 1px;
	}
`;

const CookieLink = styled(ExternalLink)`
	display: inline-block;
	margin-left: 15px;
	margin-bottom: 5px;
	background-size: 0 1px;
	// Overwrite OneTrust styles
	border: none!important;
	background-color: transparent;
	padding: 0!important;
	font-size: 12px!important;
	line-height: 16px!important;
	color: currentColor!important;
	transition: background-size 0.5s!important;
	font-weight: 300;

	&:hover,
	&:focus {
		background-size: 100% 1px;
		background-color: transparent!important;
	}
`;

const FHLogoLink = styled(ExternalLink)`
	position: absolute;
	right: 0;
	bottom: 0;
	background: none;
	transition: opacity 0.5s;

	&:hover {
		opacity: 0.5;
	}

	@media (max-width: 640px) {
		position: relative;
		margin-bottom: 20px;
	}
`;

interface IFooterProps {
	gameLogoURL: string;
	creatorLogoURL: string;
}

const Footer: React.FC<IFooterProps> = ({gameLogoURL, creatorLogoURL}) => (
	<Container>
		<TopBox>
			<GameLogoLink>
				<img src={gameLogoURL} alt="DP World Tour Fantasy" />
			</GameLogoLink>
		</TopBox>
		<MiddleBox>
			<p></p>
		</MiddleBox>
		<BottomBox>
			<FHLogoLink href="https://geniussports.com/">
				<img src={creatorLogoURL} alt="Genius Sports" />
			</FHLogoLink>
			<Nav>
				<NavLink to="/help/terms">
					Terms & Conditions
				</NavLink>
				{IS_SECRET_PASSED ? <NavLink to="/help/contact">Contact Us</NavLink> : null}
				<NavLink to="/help/privacy">Privacy Policy</NavLink>
				<CookieLink as="button" id="ot-sdk-btn" className="ot-sdk-show-settings">Cookies Choices</CookieLink>
				<NavLink to="/cookies">Cookies List</NavLink>
			</Nav>
		</BottomBox>
	</Container>
);

export const FooterLight: React.FC = () => (
	<Wrapper>
		<Footer gameLogoURL={logoDark} creatorLogoURL={geniusLogoDark} />
	</Wrapper>
);

export const FooterDark: React.FC = () => (
	<WrapperDark>
		<Footer gameLogoURL={logoLight} creatorLogoURL={geniusLogoLight} />
	</WrapperDark>
);
