import {IStore} from "modules/types/store";
import {createSelector} from "reselect";
import {filter, find, findIndex, last} from "lodash";
import {EventStatus} from "modules/enums";

export const getEvents = ({events}: IStore) =>
	filter(events.list, ({status}) => status !== EventStatus.Cancelled);

export const getMajorEvents = createSelector(getEvents,
	(events) => events.filter((event) => event.isMajor));

export const getSelectedEventID = ({events}: IStore) => events.selectedEventID;

export const getFirstScheduledEvent = createSelector(getEvents, (events) =>
	find(events, {status: EventStatus.Scheduled})
);

export const getFutureEvents = createSelector(getEvents, (events) =>
	filter(events, {status: EventStatus.Scheduled})
);

export const getNotScheduledEvents = createSelector(getEvents, (events) =>
	filter(events, ({status}) => [EventStatus.Complete, EventStatus.Active].includes(status))
);

export const getActiveEvent = createSelector(getEvents, (events) =>
	find(events, {status: EventStatus.Active})
);

export const getLastEvent = createSelector(getEvents, (events) => last(events));

export const getActualEvent = createSelector(
	getFirstScheduledEvent,
	getActiveEvent,
	getLastEvent,
	(scheduledEvent, activeEvent, lastEvent) => activeEvent || scheduledEvent || lastEvent
);

export const getNextEvent = createSelector(getEvents, getActualEvent, (events, actualEvent) => {
	const index = findIndex(events, {id: actualEvent?.id});
	return index === -1 ? null : events[index + 1];
});

export const getViewedEvent = createSelector(
	getEvents,
	getActualEvent,
	getSelectedEventID,
	(events, actualEvent, selectedEventID) => {
		const viewedEventID = selectedEventID || actualEvent?.id;
		const viewedEventIndex = findIndex(events, ({id}) => id === viewedEventID);
		const viewedEvent = events[viewedEventIndex];

		if (!viewedEvent) {
			return null;
		}

		return {
			...viewedEvent,
			prevEvent: events[viewedEventIndex - 1] || null,
			nextEvent: events[viewedEventIndex + 1] || null,
		};
	}
);

export const getEventByID = createSelector(
	getEvents,
	(events) => (eventID?: number | null) => events.find(({id}) => id === eventID)
);
