import React, {Fragment, useEffect} from "react";
import {
	CloseButton,
	Modal as ModalBase,
	ModalContent,
	ModalInner,
	Overlay,
} from "components/Modals/common";
import styled from "styled-components";
import {GIGYA_SCREENSET} from "modules/constants";

interface IProps {
	onClose: () => void;
}

const Modal = styled(ModalBase)`
	@media (min-width: 441px) {
		max-width: 100%;
		width: auto;
	}
`;

const GigyaModalContent = styled(ModalContent)`
	text-align: left;

	h2 {
		margin-block-start: 0.83em;
		margin-block-end: 0.83em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		font-size:1.5rem;
		font-weight:700;
		line-height:1.3333;

		@media only screen and (min-width:48rem){
			h2 {
				font-size:2rem;
				line-height:1.25
			}
		}
		@media only screen and (min-width:62rem){
			h2 {
				font-size:2.5rem;
				line-height:1.2
			}
		}
		@media only screen and (min-width:78.75rem){
			h2 {
				font-size:3rem;
				line-height:1.1667
			}
		}
	}

	select {
		appearance: none;
	}

	input {
		border: 0;
		background: none;
	}
`;

export const RegistrationGigyaModal: React.FC<IProps> = (props) => {
	const {onClose} = props;

	useEffect(() => {
		window.gigya?.accounts.showScreenSet({
			screenSet: GIGYA_SCREENSET,
			containerID: "registration-modal",
			startScreen: "gigya-register-screen",
		});
	}, []);

	return (
		<Fragment>
			<Overlay onClick={onClose} />
			<Modal className="section-layout">
				<ModalInner className="user--registration-modal">
					<CloseButton onClick={onClose} />
					<GigyaModalContent id="registration-modal" className="user--modal-contents user--modal-nest" />
				</ModalInner>
			</Modal>
		</Fragment>
	);
};

export const LoginGigyaModal: React.FC<IProps> = (props) => {
	const {onClose} = props;

	useEffect(() => {
		window.gigya?.accounts.showScreenSet({
			screenSet: GIGYA_SCREENSET,
			containerID: "login-modal",
		});
	}, []);

	return (
		<Fragment>
			<Overlay onClick={onClose} />
			<Modal>
				<ModalInner className="user--login-modal">
					<CloseButton onClick={onClose} />
					<GigyaModalContent id="login-modal" className="user--modal-contents user--modal-nest" />
				</ModalInner>
			</Modal>
		</Fragment>
	);
};
