import React, {useCallback, useEffect, useState} from 'react';
import logo from "assets/img/logo-dark-fantasy.svg";
import {ModalWindow} from "components/Modals/common";
import styled from "styled-components";
import {getIsUserRegisteredForGame, Storage} from "modules/utils";
import {useSelector} from "react-redux";
import {getUser} from "modules/selectors";
import {GAME_TYPE, SHOW_SEASON_TOGGLE_MODAL} from 'modules';

const Wrapper = styled.div`
  img {
    max-width: 240px;
    width: 100%;
    max-height: 110px;
    height: auto;
  }
`

export const SeasonToggleModal: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const user = useSelector(getUser)!;
    const isVisible = getIsUserRegisteredForGame(user, GAME_TYPE, 2023);

    const close = useCallback(() => {
        Storage.SET(SHOW_SEASON_TOGGLE_MODAL, "true");
        setIsOpen(false);
    }, [setIsOpen])

    useEffect(() => {
        const isViewed = JSON.parse(Storage.GET(SHOW_SEASON_TOGGLE_MODAL) || "false") as boolean;
        if (!isViewed && isVisible) {
            setIsOpen(true)
        }
    }, [isVisible, setIsOpen])

    if (!isOpen) {
        return null;
    }

    return (
        <Wrapper>
            <ModalWindow
                title="Welcome to Fantasy DP World Tour 2024!"
                message="The 2023 season is now complete and we hope you enjoyed playing. If you'd like to review how you did each week, including viewing your teams, scores and leagues, you can see this at any point in the season using the season toggle in the top right and changing to 2023."
                onClose={close}
                iconSrc={logo}
            />
        </Wrapper>);
}
