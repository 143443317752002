import {createReducer} from "redux-act";
import {
	clearLeagueInvitesState,
	clearLeagueRankings,
	clearRankingsMonths,
	clearLeaguesForJoin,
	clearLeagueUsersList,
	clearTempLeague,
	createLeague,
	createLeagueFailed,
	createLeagueSuccess,
	deleteRollbackLeagueSuccess,
	fetchLeagueMonthlyRankings,
	fetchLeagueRankings,
	fetchLeagueRankingsFailed,
	fetchLeagueRankingsSuccess,
	fetchLeagueUsersList,
	fetchLeagueUsersListFailed,
	fetchLeagueUsersListSuccess,
	fetchMoreLeagueMonthlyRankings,
	fetchMoreLeagueRankings,
	fetchMoreLeagueUsersList,
	fetchMoreRolexRankings,
	fetchMoreTopTenRankings,
	fetchRankingsMonthsSuccess,
	fetchRolexRankings,
	fetchTopTenRankings,
	ILeague,
	ILeagueRankingsResult,
	ILeagueUsersListResult,
	IMyLeague,
	inviteToLeague,
	inviteToLeagueFailed,
	inviteToLeagueSuccess, IRankingsMonth, IRollbackLeague,
	joinToLeague,
	joinToLeagueFailed,
	joinToLeagueSuccess,
	leaveLeague,
	leaveLeagueFailed,
	leaveLeagueSuccess,
	removeUserFromLeagueSuccess,
	resetLeaveLeagueRequestState,
	resetUpdateLeagueRequestState, rollbackAllLeaguesSuccess, rollbackLeagueSuccess,
	showLeague,
	showLeagueFailed,
	showLeaguesForJoin,
	showLeaguesForJoinFailed,
	showLeaguesForJoinSuccess,
	showLeagueSuccess,
	showMyLeagues,
	showMyLeaguesFailed,
	showMyLeaguesSuccess,
	updateLeague,
	updateLeagueFailed,
	updateLeagueSuccess, IEventForLeaderboard, clearEventForLeaderboardRival, selectEventForLeaderboardRival,
} from "modules/actions";
import {RequestState} from "modules/enums";
import {isEqual} from "lodash";

interface ILeaguesList {
	list: IMyLeague[];
	inactiveLeagues: IRollbackLeague[];
	requestState: RequestState;
	nextPage: boolean;
}

interface IReducer {
	joinedLeaguesFlags: Record<number, boolean>;
	myLeagues: ILeaguesList;
	leaguesForJoin: Omit<ILeaguesList, "inactiveLeagues"> & {
		navigateToLeagueID: number | null;
	};
	leagueRankings: ILeagueRankingsResult & {
		requestState: RequestState;
	};
	leagueUsersList: ILeagueUsersListResult & {
		requestState: RequestState;
	};
	leagueInvitesRequestState: RequestState;
	updateSettingsRequestState: RequestState;
	leaveLeagueRequestState: RequestState;
	standingMonths: IRankingsMonth[];
	tempCreatedLeague: Partial<ILeague> & {
		requestState: RequestState;
	};
	rankingsSelectedEvent: IEventForLeaderboard;
}

const defaultState: IReducer = {
	leagueRankings: {
		requestState: RequestState.IDLE,
		ranking: [],
		next_page: null,
		user: null
	},
	leagueUsersList: {
		requestState: RequestState.IDLE,
		users: [],
		nextPage: false,
	},
	joinedLeaguesFlags: {},
	myLeagues: {
		list: [],
		inactiveLeagues: [],
		requestState: RequestState.IDLE,
		nextPage: false,
	},
	leaguesForJoin: {
		list: [],
		requestState: RequestState.IDLE,
		nextPage: false,
		navigateToLeagueID: null,
	},
	leagueInvitesRequestState: RequestState.IDLE,
	updateSettingsRequestState: RequestState.IDLE,
	leaveLeagueRequestState: RequestState.IDLE,
	standingMonths: [],
	tempCreatedLeague: {
		requestState: RequestState.IDLE,
	},
	rankingsSelectedEvent: undefined
};

const onJoinLeagueRequestStart = (state: IReducer) => ({
	...state,
	leaguesForJoin: {
		...state.leaguesForJoin,
		requestState: RequestState.Requested,
	},
});

const onJoinLeagueRequestEnd = (state: IReducer) => ({
	...state,
	leaguesForJoin: {
		...state.leaguesForJoin,
		requestState: RequestState.Received,
	},
});

const onRequestLeagueStart = (state: IReducer) => ({
	...state,
	myLeagues: {
		...state.myLeagues,
		requestState: RequestState.Requested,
	},
});

const onRequestLeagueEnd = (state: IReducer) => ({
	...state,
	myLeagues: {
		...state.myLeagues,
		requestState: RequestState.Received,
	},
});

const onLeagueRollover = (state: IReducer, leagueId: number) => ({
	...state,
	myLeagues: {
		...state.myLeagues,
		inactiveLeagues: state.myLeagues.inactiveLeagues.filter((e) => e.id !== leagueId)
	},
})

const onFetchRankingsMonthsSuccess = (state: IReducer, payload: IRankingsMonth[]): IReducer => ({
	...state,
	standingMonths: payload
})

const onClearRankingsMonths = (state: IReducer, payload: IRankingsMonth[]): IReducer => ({
	...state,
	standingMonths: []
})

const onRequestLeagueRankingsStart = (state: IReducer) => ({
	...state,
	leagueRankings: {
		...state.leagueRankings,
		requestState: RequestState.Requested,
	},
});

const onRequestLeagueUsersListStart = (state: IReducer) => ({
    ...state,
    leagueUsersList: {
        ...state.leagueUsersList,
        requestState: RequestState.Requested,
    },
});

const onFetchLeagueUsersListFailed = (state: IReducer) => ({
	...state,
	leagueUsersList: {
		...state.leagueUsersList,
		requestState: RequestState.Received,
	},
});

const mergeLeagues = (oldLeague: IMyLeague, newLeague: IMyLeague | ILeague): IMyLeague =>
	isEqual(oldLeague.id, newLeague.id)
		? {
				...oldLeague,
				...newLeague,
		  }
		: oldLeague;

export const leagues = createReducer<typeof defaultState>({}, defaultState)
	.on(clearTempLeague, (state) => ({
		...state,
		tempCreatedLeague: defaultState.tempCreatedLeague,
	}))
	.on(createLeague, (state) => ({
		...state,
		tempCreatedLeague: {
			...state.tempCreatedLeague,
			requestState: RequestState.Requested,
		},
	}))
	.on(createLeagueFailed, (state) => ({
		...state,
		tempCreatedLeague: {
			...state.tempCreatedLeague,
			requestState: RequestState.Received,
		},
	}))
	.on(createLeagueSuccess, (state, league) => ({
		...state,
		myLeagues: {
			...state.myLeagues,
			list: [
				{
					...league,
					rank: null,
					last_event_rank: null,
					chairman: null,
				},
				...state.myLeagues.list,
			],
		},
		tempCreatedLeague: {
			...state.tempCreatedLeague,
			requestState: RequestState.Received,
			...league,
		},
	}))
	.on(inviteToLeague, (state) => ({
		...state,
		leagueInvitesRequestState: RequestState.Requested,
	}))
	.on(inviteToLeagueSuccess, (state) => ({
		...state,
		leagueInvitesRequestState: RequestState.Received,
	}))
	.on(inviteToLeagueFailed, (state) => ({
		...state,
		leagueInvitesRequestState: RequestState.IDLE,
	}))
	.on(clearLeagueInvitesState, (state) => ({
		...state,
		leagueInvitesRequestState: RequestState.IDLE,
	}))
	.on(showMyLeagues, onRequestLeagueStart)
	.on(showMyLeaguesSuccess, (state, {leagues, inactiveLeagues, nextPage}) => ({
		...state,
		myLeagues: {
			...state.myLeagues,
			list: leagues,
			inactiveLeagues,
			nextPage,
			requestState: RequestState.Received,
		},
	}))
	.on(showMyLeaguesFailed, onRequestLeagueEnd)
	.on(showLeaguesForJoin, onJoinLeagueRequestStart)
	.on(showLeaguesForJoinSuccess, (state, {leagues, nextPage}) => ({
		...state,
		leaguesForJoin: {
			...state.leaguesForJoin,
			list: leagues,
			nextPage,
			requestState: RequestState.Received,
		},
	}))
	.on(showLeaguesForJoinFailed, onJoinLeagueRequestEnd)
	.on(joinToLeague, onJoinLeagueRequestStart)
	.on(joinToLeagueSuccess, (state, league) => ({
		...state,
		joinedLeaguesFlags: {
			...state.joinedLeaguesFlags,
			[league.id]: true,
		},
		myLeagues: {
			...state.myLeagues,
			list: [league, ...state.myLeagues.list],
		},
		leaguesForJoin: {
			...state.leaguesForJoin,
			navigateToLeagueID: league.id,
			requestState: RequestState.Received,
		},
	}))
	.on(joinToLeagueFailed, onJoinLeagueRequestEnd)
	.on(clearLeaguesForJoin, (state) => ({
		...state,
		leaguesForJoin: defaultState.leaguesForJoin,
	}))
	.on(showLeague, onRequestLeagueStart)
	.on(showLeagueSuccess, (state, {league, isJoined}) => {
		const hasLeague = state.myLeagues.list.find(({id}) => id === league.id);
		const list = hasLeague
			? state.myLeagues.list.map((oldLeague) => mergeLeagues(oldLeague, league))
			: [league, ...state.myLeagues.list];

		return {
			...state,
			joinedLeaguesFlags: {
				...state.joinedLeaguesFlags,
				[league.id]: isJoined,
			},
			myLeagues: {
				...state.myLeagues,
				list,
				requestState: RequestState.Received,
			},
		};
	})
	.on(showLeagueFailed, onRequestLeagueEnd)
	.on(updateLeague, (state) => ({
		...state,
		updateSettingsRequestState: RequestState.Requested,
	}))
	.on(updateLeagueSuccess, (state, league) => ({
		...state,
		updateSettingsRequestState: RequestState.Received,
		myLeagues: {
			...state.myLeagues,
			list: state.myLeagues.list.map((oldLeague) => mergeLeagues(oldLeague, league)),
		},
	}))
	.on(updateLeagueFailed, (state) => ({
		...state,
		updateSettingsRequestState: RequestState.Received,
	}))
	.on(resetUpdateLeagueRequestState, (state) => ({
		...state,
		updateSettingsRequestState: RequestState.IDLE,
	}))

	.on(leaveLeague, (state) => ({
		...state,
		leaveLeagueRequestState: RequestState.Requested,
	}))
	.on(leaveLeagueSuccess, (state, leagueID) => ({
		...state,
		leaveLeagueRequestState: RequestState.Received,
		myLeagues: {
			...state.myLeagues,
			list: state.myLeagues.list.filter((league) => league.id !== leagueID),
		},
	}))
	.on(leaveLeagueFailed, (state) => ({
		...state,
		leaveLeagueRequestState: RequestState.IDLE,
	}))
	.on(resetLeaveLeagueRequestState, (state) => ({
		...state,
		leaveLeagueRequestState: RequestState.IDLE,
	}))
	.on(fetchLeagueMonthlyRankings, onRequestLeagueRankingsStart)
	.on(fetchMoreLeagueMonthlyRankings, onRequestLeagueRankingsStart)
	.on(fetchTopTenRankings, onRequestLeagueRankingsStart)
	.on(fetchMoreTopTenRankings, onRequestLeagueRankingsStart)
	.on(fetchRolexRankings, onRequestLeagueRankingsStart)
	.on(fetchMoreRolexRankings, onRequestLeagueRankingsStart)
	.on(fetchLeagueRankings, onRequestLeagueRankingsStart)
	.on(fetchMoreLeagueRankings, onRequestLeagueRankingsStart)
	.on(fetchLeagueRankingsSuccess, (state, rankings) => ({
		...state,
		leagueRankings: {
			...state.leagueRankings,
			requestState: RequestState.Received,
			...rankings,
		},
	}))
	.on(fetchLeagueRankingsFailed, (state) => ({
		...state,
		leagueRankings: {
			...state.leagueRankings,
			requestState: RequestState.Received,
		},
	}))
	.on(clearLeagueRankings, (state) => ({
		...state,
		leagueRankings: defaultState.leagueRankings,
	}))
	.on(rollbackAllLeaguesSuccess,  (state) => ({
		...state,
		myLeagues: {
			...state.myLeagues,
			inactiveLeagues: []
		},
	}))
	.on(rollbackLeagueSuccess, onLeagueRollover)
	.on(deleteRollbackLeagueSuccess, onLeagueRollover)
	.on(fetchRankingsMonthsSuccess, onFetchRankingsMonthsSuccess)
	.on(clearRankingsMonths, onClearRankingsMonths)
    .on(fetchLeagueUsersList, onRequestLeagueUsersListStart)
    .on(fetchMoreLeagueUsersList, onRequestLeagueUsersListStart)
    .on(fetchLeagueUsersListSuccess, (state, usersResult) => ({
        ...state,
        leagueUsersList: {
            ...state.leagueUsersList,
            requestState: RequestState.Received,
            ...usersResult,
        },
    }))
    .on(clearLeagueUsersList, (state) => ({
        ...state,
        leagueUsersList: defaultState.leagueUsersList,
    }))
    .on(fetchLeagueUsersList, onRequestLeagueUsersListStart)
    .on(fetchMoreLeagueUsersList, onRequestLeagueUsersListStart)
    .on(fetchLeagueUsersListFailed,onFetchLeagueUsersListFailed)
    .on(clearLeagueUsersList, (state) => ({
        ...state,
        leagueUsersList: defaultState.leagueUsersList,
    }))
    .on(removeUserFromLeagueSuccess, (state, removedUserID) => ({
        ...state,
        leagueUsersList: {
            ...state.leagueUsersList,
            users: state.leagueUsersList.users.filter(({id}) => id !== removedUserID),
            requestState: RequestState.Received,
        },
    }))
	.on(selectEventForLeaderboardRival, (state, payload) => ({
		...state,
		rankingsSelectedEvent: payload
	}))
	.on(clearEventForLeaderboardRival, (state) => ({
		...state,
		rankingsSelectedEvent: undefined
	}))
