import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {hideWelcomeModal, openTutorial} from "modules/actions";
import {ModalContent, ModalText, ModalTitle} from "components/Modals/common";
import {BaseModal, ButtonPrimaryAlt} from "components";
import styled from "styled-components";
import {useMedia} from "react-use";
import {getIsShowWelcomeModal} from "modules/selectors";
import {SHOW_INITIAL_MODAL} from "modules";
import {Storage} from "modules/utils";

const ModalContentStyled = styled(ModalContent)`
	padding: 5px;
`;

const Title = styled(ModalTitle)`
	font-size: 26px;
	line-height: 29px;
	margin: 25px 0;

	@media (max-width: 768px) {
		font-size: 20px;
		margin: 10px 0 8px;
	}
`;

const ButtonsBoxStyled = styled.div`
	padding-top: 40px;
	justify-content: space-between;
	display: flex;
	align-items: center;

	button {
		margin: 0 10px;
		width: 100%;
	}

	@media (max-width: 768px) {
		padding-top: 16px;
	}
`;

const ModalTextStyled = styled(ModalText)`
	font-size: 14px;
`;

export const WelcomeModal: React.FC = () => {
	const dispatch = useDispatch();
	const isShowWelcomeModal = useSelector(getIsShowWelcomeModal);
	const isMobile = useMedia("(max-width: 768px)");
	const text = isMobile
		? "To get started pick six golfers. Earn points on how well they perform."
		: "To get started, you must select 6 golfers. You will earn points based on how well they perform throughout the tournament.";

	const handlerClose = useCallback(() => {
		dispatch(hideWelcomeModal());
	}, [dispatch]);

	const handlerClick = useCallback(() => {
		dispatch(hideWelcomeModal());
		dispatch(openTutorial());
	}, [dispatch]);

	useEffect(() => {
		if (isShowWelcomeModal) {
			Storage.SET(SHOW_INITIAL_MODAL, "false");
		}
	}, [isShowWelcomeModal]);

	if (!isShowWelcomeModal) {
		return null;
	}

	return (
		<BaseModal handlerClose={handlerClose}>
			<ModalContentStyled>
				<Title>Welcome!</Title>
				<ModalTextStyled>{text}</ModalTextStyled>
				<ButtonsBoxStyled>
					<ButtonPrimaryAlt onClick={handlerClose}>Play now</ButtonPrimaryAlt>
					<ButtonPrimaryAlt onClick={handlerClick}>View tutorial</ButtonPrimaryAlt>
				</ButtonsBoxStyled>
			</ModalContentStyled>
		</BaseModal>
	);
};
