import {createAction} from "redux-act";

export interface ISeasonStats {
	birdies: number;
	birdies_average: number;
	green_in_regulation: number;
    gir_average: number;
}

export const requestSeasonStatsJSON = createAction();
export const requestSeasonStatsJSONSuccess = createAction<Record<number, ISeasonStats>>();
export const requestSeasonStatsJSONFailed = createAction();
