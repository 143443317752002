import {createReducer} from "redux-act";
import {RequestState} from "modules/enums";
import {clearContact, postContact, postContactFailed, postContactSuccess} from "modules/actions";

const initial = {
	requestState: RequestState.IDLE,
};

export const contact = createReducer<typeof initial>({}, initial)
	.on(postContact, () => ({
		requestState: RequestState.Requested,
	}))
	.on(postContactSuccess, () => ({
		requestState: RequestState.Received,
	}))
	.on(postContactFailed, () => initial)
	.on(clearContact, () => initial);
