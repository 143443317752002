import React, {useCallback, useEffect} from 'react';
import styled from "styled-components";
import {MenuItem, Select} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {SelectChangeEvent} from "@mui/material/Select/SelectInput";
import {useDispatch, useSelector} from "react-redux";
import {requestEventsJSON, setSeason} from "modules/actions";
import {getIsCurrentSeason, getSeason, getUser} from "modules/selectors";
import {getIsUserRegisteredForGame} from "modules/utils/User";
import {GAME_TYPE, NOT_ALLOWED_ROLLOVER_URLS} from "modules";
import {useLocation, useNavigate} from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  margin-left: auto;
	margin-right: 32px;

  .MuiOutlinedInput-root {
    height: 24px;
    padding: 0;
    background: #FFFFFF;
    border-radius: 16px;

    color: var(--primary1);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.16px;
  }

  svg {
    width: 24px;
    height: 24px;
    color: #00E68C;
    pointer-events: none;
    position: absolute;
    right: 4px;
  }

  .MuiSelect-select {
    padding: 0 32px 0 8px !important;

    &[aria-expanded="true"] + svg {
      transform: rotate(180deg);
    }
  }

  fieldset {
    border: none;
    border-radius: 16px;
  }

  @media (max-width: 768px) {
    .MuiOutlinedInput-root {
      height: 32px
    }
  }
`;

export const SeasonToggle: React.FC = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const user = useSelector(getUser);
	const season = useSelector(getSeason);
	const isCurrentSeason = useSelector(getIsCurrentSeason);
	const isVisible = getIsUserRegisteredForGame(user, GAME_TYPE, 2023);

	useEffect(() => {
		dispatch(requestEventsJSON());
	}, [dispatch, season])

	useEffect(() => {
		if (NOT_ALLOWED_ROLLOVER_URLS.includes(location.pathname) && !isCurrentSeason) {
			navigate("/team");
		}
	}, [navigate, location, isCurrentSeason]);

	const handleChange = useCallback((event: SelectChangeEvent<string>) => {
		const value = event.target.value;
		if (!value) {
			return;
		}

		dispatch(setSeason(value));
	}, [dispatch])

	if (!isVisible) {
		return null;
	}

	return (
		<Wrapper>
			<Select
				name="season"
				defaultValue="2024"
				value={season}
				onChange={handleChange}
				IconComponent={() => <KeyboardArrowDownIcon/>}>
				<MenuItem value="2023">2023 Season</MenuItem>
				<MenuItem value="2024">2024 Season</MenuItem>
			</Select>
		</Wrapper>
	);
}
