import {createReducer} from "redux-act";
import {
	IEvent,
	requestEventsJSONSuccess,
	setViewedEventID,
	switchEventStatusToLive,
} from "modules/actions";
import {EventStatus} from "modules/enums";

interface IReducer {
	list: IEvent[];
	selectedEventID: null | number;
}

const defaultState: IReducer = {
	list: [],
	selectedEventID: null,
};

export const events = createReducer<typeof defaultState>({}, defaultState)
	.on(requestEventsJSONSuccess, (state, events) => ({
		...state,
		list: events,
	}))
	.on(setViewedEventID, (state, selectedEventID) => ({
		...state,
		selectedEventID,
	}))
	.on(switchEventStatusToLive, (state, eventID) => ({
		...state,
		list: state.list.map((event) =>
			event.id === eventID
				? {
						...event,
						status: EventStatus.Active,
				  }
				: event
		),
	}));
