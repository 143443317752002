import React from "react";
import {Navigate, Outlet, RouteProps} from "react-router-dom";
import {useSelector} from "react-redux";
import {isLoggedIn, isLogOutWasCalled} from "modules/selectors";
import {useLocation} from "react-router";

export const PrivateRoute: React.FC<RouteProps> = () => {
	const wasLoggedOut = useSelector(isLogOutWasCalled);
	const location = useLocation();

	if (useSelector(isLoggedIn)) {
		return <Outlet />;
	}

	const state = wasLoggedOut ? {} : {from: location};

	return <Navigate to="/" state={state} replace />;
};
