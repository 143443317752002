import {ComponentType} from "react";

export * from "./Api";
export * from "./hooks";
export * from "./Storage";
export * from "./socialShare";
export * from "./Countdown";
export * from "./User";

type Factory<T> = () => Promise<{
	default: ComponentType<T>;
}>;

export function retryFailLoad<T>(fn: Factory<T>, retriesLeft = 5, interval = 1000): Factory<T> {
	return () =>
		new Promise((resolve, reject) => {
			fn()
				.then(resolve)
				.catch((error: unknown) => {
					setTimeout(() => {
						if (retriesLeft === 1) {
							reject(error);
							return;
						}

						retryFailLoad(fn, retriesLeft - 1, interval)().then(resolve, reject);
					}, interval);
				});
		});
}

export const createConnextraScriptTag = (src: string) => {
	const elementName = "script";
	const SCRIPT_ID = "connextra-tagging";
	document.getElementById(SCRIPT_ID)?.remove();

	const script = document.createElement(elementName);
	script.id = SCRIPT_ID;
	script.src = src;
	script.async = true;
	script.defer = true;

	const firstJS = document.getElementsByTagName(elementName)[0];
	firstJS.parentNode!.insertBefore(script, firstJS);

	return script;
};

export const choice = <T = unknown>(elements: Array<T>) =>
	elements[Math.floor(Math.random() * elements.length)];

export function UCFirst(value: string): string {
	if (!value) {
		return "";
	}

	return value.charAt(0).toUpperCase() + value.slice(1);
}

export interface IUserDetails {
	user_id: string;
	user_emid: string;
	user_phid?: string;
}

export interface ITrackingParameters {
	event: string;
	user_details: IUserDetails;
	[key: string]: unknown;
}

export async function digestMessage(message: string) {
	const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
	const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8); // hash the message
	const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
	return hashArray
		.map((b) => b.toString(16).padStart(2, "0"))
		.join(""); // convert bytes to hex string
}

export const trackUser = async (params: ITrackingParameters) => {
	params.user_details.user_emid = await digestMessage(params.user_details.user_emid);
	window.dataLayer?.push(params);
}
