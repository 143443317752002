import {createAction} from "redux-act";
import {EventStatus} from "modules/enums";

export interface ICourse {
	name: string;
	city: string;
	country: string;
}

export interface IEvent {
	id: number;
	name: string;
	dateStart: string;
	dateEnd: string;
	isMajor: boolean;
	isWgc: boolean;
	isMatchPlay: boolean;
	fieldSize?: number;
	country: string;
	status: EventStatus;
	courses?: ICourse[];
}

export const requestEventsJSON = createAction();
export const requestEventsJSONSuccess = createAction<IEvent[]>();
export const requestEventsJSONFailed = createAction();

export const setViewedEventID = createAction<number | null>();
export const switchEventStatusToLive = createAction<number>();
