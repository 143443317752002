import styled from "styled-components";
import React, {AllHTMLAttributes} from "react";
import TextField from "@mui/material/TextField";
import SelectMUI from "@mui/material/Select";
import {FormControl} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckIcon from "@mui/icons-material/Check";
import {SelectProps} from "@mui/material/Select/Select";

export const FormField = styled.div`
	margin-bottom: 20px;
`;

const BaseCheckboxContainer = styled.label`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: relative;
	cursor: pointer;
	border: 1px solid #3230be;
	min-width: 25px;
	width: 25px;
	height: 25px;
	box-sizing: border-box;
	border-radius: 4px;
	overflow: hidden;

	svg {
		color: transparent;
		font-size: 20px;
		z-index: 1;
	}

	span {
		display: none;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: #3230be;
	}

	input {
		position: absolute;
		margin: 0;
		padding: 0;
		opacity: 0;
		cursor: pointer;

		&:checked + svg {
			color: #fff;
		}

		&:checked + svg + span {
			display: block;
		}

		&:disabled {
			cursor: default;

			& + svg {
				opacity: 0.5;
			}
		}
	}
`;

const BaseCheckbox: React.FC<AllHTMLAttributes<HTMLInputElement>> = ({id, className, ...rest}) => (
	<BaseCheckboxContainer className={className} htmlFor={id}>
		<input id={id} {...rest} />
		<CheckIcon />
		<span />
	</BaseCheckboxContainer>
);

const BaseLabel = styled.label`
	text-align: left;
	font-size: 12px;
	line-height: 17px;
	font-weight: 300;
`;

export const Label = styled(BaseLabel)`
	cursor: pointer;
`;

export const RadioButton = styled(BaseCheckbox)``;

RadioButton.defaultProps = {
	type: "radio",
};

export const Checkbox = styled(BaseCheckbox)``;

Checkbox.defaultProps = {
	type: "checkbox",
};

export const SelectFormControl = styled(FormControl)`
	background: transparent;
	border-radius: 4px;

	.MuiFilledInput-root {
		background: transparent;
	}

	.MuiInputLabel-root,
	.MuiInputLabel-root.Mui-focused {
		color: #a3a3a3;
	}

	.MuiFilledInput-root::after {
		border-color: #a3a3a3;
	}

	.MuiSelect-filled {
		text-align: left;
	}

	.MuiSvgIcon-root {
		font-size: 28px;
		color: rgba(0, 0, 0, 0.87);
	}
`;

SelectFormControl.defaultProps = {
	variant: "filled",
	fullWidth: true,
	size: "small",
};

export const SelectStyled = styled(SelectMUI)`
	color: #a3a3a3;
	border-radius: 4px;

	&.MuiFilledInput-root::before,
	&.MuiFilledInput-root::after {
		border-radius: 3px;
	}
`;

SelectStyled.defaultProps = {
	variant: "filled",
	size: "small",
	fullWidth: true,
};

export const Select: React.FC<SelectProps> = ({
	label,
	defaultValue = "",
	name,
	required,
	children,
	...rest
}) => (
	<SelectFormControl variant="filled" fullWidth>
		<InputLabel required={required}>{label}</InputLabel>
		<SelectStyled
			defaultValue={defaultValue}
			name={name}
			label={label}
			required={required}
			IconComponent={KeyboardArrowDownIcon}
			{...rest}>
			{children}
		</SelectStyled>
	</SelectFormControl>
);

export const Input = styled(TextField)`
	background: transparent;
	color: #a3a3a3;
	border-radius: 4px;

	.MuiFilledInput-root {
		background: transparent;
	}

	.MuiInputLabel-root,
	.MuiInputLabel-root.Mui-focused {
		color: #a3a3a3;
	}

	.MuiFilledInput-root::before,
	.MuiFilledInput-root::after {
		border-radius: 3px;
	}

	.MuiFilledInput-root::after {
		border-color: #a3a3a3;
	}
`;

Input.defaultProps = {
	variant: "filled",
	size: "small",
	fullWidth: true,
};

export const ErrorText = styled.p`
	color: var(--accent2);
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0.5px;
	line-height: 18px;
	margin-bottom: 20px;
`;
