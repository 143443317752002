import {call, put} from "typed-redux-saga";
import {IError} from "modules/types";
import Api from "modules/utils/Api";
import {postContact, postContactFailed, postContactSuccess} from "modules/actions";

export const postContactSaga = function* ({payload}: ReturnType<typeof postContact>) {
	try {
		yield* call(Api.Common.contact, payload);
		yield* put(postContactSuccess());
	} catch (e) {
		yield* put(postContactFailed(e as IError));
	}
};
