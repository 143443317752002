import {createAction} from "redux-act";
import { ISeasonStats } from "modules/actions/stats";

export interface IPlayerStatsByEvent {
	best_round: number | null;
	eagles: number | null;
	birdies: number | null;
	pars: number | null;
	bogeys: number | null;
	double_bogeys: number | null;
	low_round: number | null;
	score: number | null;
	fantasy_points: number | null;
	owned_by?: number;
	date_start: string;
	position: {
		is_tie: boolean;
		position: number | string | null;
		missed_cut: boolean;
		position_desc: number | string | null;
	};
	triple_bogeys_or_worse: number | null;
}

export interface IPlayer {
	id: number;
	firstname: string;
	lastname: string;
	is_dpwt_member: boolean;
	dpwt_wins: number;
	stats: {
		form: string;
		last_year: Record<number, number>;
		positions: Record<
			number,
			{
				is_tie: boolean;
				position: number;
				position_desc: number;
				missed_cut: boolean;
			}
		>;
		avg_points: number;
		event_scores: Record<number, number>;
		total_points: number;
		owned_by: number;
	};
	world_rank: number;
	dpwt_rank: number;
	event_stats: {
		best_round: number | null;
		eagles: number | null;
		birdies: number | null;
		pars: number | null;
		bogeys: number | null;
		double_bogeys: number | null;
		score: number | null;
		triple_bogeys_or_worse: number | null;
		green_in_regulation: number | null;
	};
	above_cut_off: boolean;
	country: string;
}

export interface IPlayerWithSeasonStats extends IPlayer {
	season_stats: ISeasonStats;
}

export interface ISetPlayersByEvent {
	players: IPlayer[];
	eventID: number;
}

export const requestPlayersJSON = createAction<number>();
export const requestPlayersJSONSuccess = createAction<ISetPlayersByEvent>();
export const requestPlayersJSONFailed = createAction();

export interface ISetPlayerStatsByPlayerID {
	stats: Record<number, IPlayerStatsByEvent>;
	playerID: number;
}

export const requestPlayerStatsJSON = createAction<number>();
export const requestPlayerStatsJSONSuccess = createAction<ISetPlayerStatsByPlayerID>();
export const requestPlayerStatsJSONFailed = createAction();
