export enum SocialNetwork {
	Facebook,
	Twitter,
	Mobile,
}

export enum ShareType {
	General = "general",
	League = "league",
}

export enum RequestState {
	IDLE,
	Requested,
	Received,
}

export enum PrivacyType {
	Private = "private",
	Public = "public",
}

export enum SortOrder {
	ASC = "asc",
	DESC = "desc",
}

export enum EventStatus {
	Scheduled = "scheduled",
	Active = "playing",
	Complete = "complete",
	Cancelled = "cancelled",
}

export enum LeagueStatus {
	Scheduled = "scheduled",
	Active = "playing",
	Complete = "complete",
	Cancelled = "cancelled",
}

export enum LeaguePrivacy {
	Public = "public",
	Private = "private",
}

export enum LeagueType {
	Overall = "overall",
	Regular = "regular",
}
