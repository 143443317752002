import {keys, reduce} from "lodash";

export const ENV = process.env.REACT_APP_ENVIRONMENT;
export const BASE_URL = process.env.REACT_APP_BASE_URL || "";
export const API_URL = process.env.REACT_APP_API_URL || "";
export const JSON_URL = process.env.REACT_APP_JSON_URL || "";
export const IMAGES_URL = process.env.REACT_APP_IMAGES_URL || "";
export const FLAGS_URL = process.env.REACT_APP_FLAGS_URL || "";
export const SHARE_URL = process.env.REACT_APP_SHARE_URL || "";

export const GIGYA_SCREENSET = process.env.REACT_APP_GIGYA_SCREENSET || "";

export const STORAGE_VERSION = 2;
export const EMAIL_REGEXP = "\\S+@\\S+\\.\\S+";
export const PASSWORD_PATTERN =
    "^((?=.*?\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[-!@#$%^&*()_+|~=`{}\\[\\]:\";'<>?,.\\/])).{8,}$";

export const LINEUP_SIZE = 6;
export const DEFAULT_CAP_SIZE = 3;
export const DPWT_MEMBER_CAP_SIZE = 5;
export const MAX_INVITES_SIZE = 5;
export const OVERALL_LEAGUE_ID = 1;
export const SHOW_INITIAL_MODAL = "showInitialModal";
export const SHOW_ROLLOVER_MODAL = "showRolloverModal";
export const SHOW_SEASON_TOGGLE_MODAL = 'showSeasonToggleModal';
export const GAME_TYPE = "FANTASY";
export const GAME_SEASON_STRING = process.env.REACT_APP_ACTIVE_SEASON;
export const GAME_SEASON = Number(GAME_SEASON_STRING);

const isSecretePassed = sessionStorage.getItem("isSecretPassed");
const IS_SECRET_ENABLED = JSON.parse(process.env.REACT_APP_IS_SECRET_ENABLED || "false") as boolean;

export const IS_SECRET_PASSED = isSecretePassed
    ? JSON.parse(isSecretePassed) === true
    : !IS_SECRET_ENABLED;

export const LeaderboardType = {
    Season: "Season",
    SeptemberChallenge: "September Challenge",
    Months: "Months",
    Tournament: "Tournament",
    Rolex: "Rolex Series",
    GlobalSwings: "Global Swings",
    MajorTournaments: "The Majors"
} as const;


export const LeaderboardTypeOffSeason = {
    Season: "Season",
    SeptemberChallenge: "September Challenge",
    Months: "Months",
    Tournament: "Tournament",
    Rolex: "Rolex Series",
} as const;

export type LeaderboardTypeValue = typeof LeaderboardType[keyof typeof LeaderboardType];

const createSlugForLeaderBoardByKey = (key: string) =>
    LeaderboardType[key as keyof typeof LeaderboardType].toLowerCase().split(" ").join("-");
export const NOT_ALLOWED_ROLLOVER_URLS = ['/help', "/prizes", "/leagues/create", "/leagues/join"];

export const LeaderboardTypeBySlug = reduce(
    keys(LeaderboardType),
    (acc, key) => {
        acc[createSlugForLeaderBoardByKey(key)] =
            LeaderboardType[key as keyof typeof LeaderboardType];
        return acc;
    },
    {} as Record<string, LeaderboardTypeValue>
);

export const LeaderboardSlugByType = reduce(
    keys(LeaderboardType),
    (acc, key) => {
        acc[LeaderboardType[key as keyof typeof LeaderboardType]] =
            createSlugForLeaderBoardByKey(key);
        return acc;
    },
    {} as Record<string, string>
);

export const SEASONS_MONTHS = [
    {id: 1, name: "January"},
    {id: 2, name: "February"},
    {id: 3, name: "March"},
    {id: 4, name: "April"},
    {id: 5, name: "May"},
    {id: 6, name: "June"},
    {id: 7, name: "July"},
    {id: 8, name: "August"},
    {id: 9, name: "September"},
    {id: 10, name: "October"},
    {id: 11, name: "November"},
    {id: 12, name: "December"},
];
export const IS_LEAGUE_MANAGE_DISABLED = false;