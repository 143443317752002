import React from "react";
import {Navigate, Outlet, RouteProps, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {isLoggedIn} from "modules/selectors";
import {get} from "lodash";

export const NotAuthOnlyRoute: React.FC<RouteProps> = (props) => {
	const location = useLocation();
	const fromPath = get(location, "state.from.pathname", "") as string;
	const fromSearch = get(location, "state.from.search", "") as string;
	const backURL = fromPath ? fromPath + fromSearch : "/team";

	if (useSelector(isLoggedIn)) {
		return <Navigate to={backURL} replace />;
	}

	return <Outlet />;
};
