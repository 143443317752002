import {createAction} from "redux-act";
import {IError} from "modules/types";

export interface IContactPayload {
	type: string;
	name: string;
	email: string;
	question: string;
}

export const postContact = createAction<IContactPayload>();
export const postContactFailed = createAction<IError>();
export const postContactSuccess = createAction();
export const clearContact = createAction();
