import React, {Fragment} from "react";
import styled from "styled-components";
import {useMedia} from "react-use";

const TooltipTitle = styled.h2`
	font-size: 20px;
	font-weight: 700;
	margin-bottom: 7px;
	line-height: 22px;
`;

const TooltipMessage = styled.p`
	font-size: 12px;
	line-height: 24px;
`;

interface ITooltipContentProps {
	title: string;
	message: string;
	mobileMessage?: string;
}

export const TooltipContent: React.FC<ITooltipContentProps> = ({title, message, mobileMessage}) => {
	const isMobile = useMedia("(max-width: 768px)");

	return (
		<Fragment>
			<TooltipTitle>{title}</TooltipTitle>
			<TooltipMessage>{mobileMessage && isMobile ? mobileMessage : message}</TooltipMessage>
		</Fragment>
	);
};
