import React, {Fragment, useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import {
	ButtonPrimaryAlt,
	Checkbox as BaseCheckbox,
	Exist,
	ExternalLink,
	Input,
	InternalLink,
	Label,
	PageTitle,
	Preloader,
} from "components";
import {useDispatch, useSelector} from "react-redux";
import {unMarkUserInfoAsUpdated, userInfoUpdate} from "modules/actions";
import {getUser, getUserInfoUpdated, getUserRequestState} from "modules/selectors";
import {isEqual} from "lodash";
import {RequestState} from "modules/enums";

const Wrapper = styled.div`
	color: #0f0f19;

	p {
		font-size: 14px;
		line-height: 20px;
		margin-bottom: 20px;
	}
`;

const Form = styled.form`
	button {
		min-width: 160px;
	}
`;

const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 20px;
	margin-bottom: 20px;

	@media (max-width: 768px) {
		grid-template-columns: 1fr;
		margin-bottom: 20px;
	}
`;

const ButtonsBox = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 16px;

	button:first-child {
		margin-left: 0;
		margin-right: auto;
	}

	@media (max-width: 768px) {
		flex-flow: column;

		button:first-child {
			width: 100%;
			margin-bottom: 40px;
		}
	}
`;

const StyledLabel = styled(Label)`
	margin-left: 15px;
	font-size: 14px;
	line-height: 18px;
	font-weight: normal;
`;

const RowCheckbox = styled.div`
	display: flex;
	align-items: center;
`;

export const Checkbox = styled(BaseCheckbox)`
	border: 1px solid currentColor;
`;

interface IForm extends HTMLFormElement {
	username: HTMLInputElement;
	opt_in: HTMLInputElement;
}

export const Account: React.FC = () => {
	const dispatch = useDispatch();

	const user = useSelector(getUser)!;
	const isUserInfoUpdated = useSelector(getUserInfoUpdated);
	const requestState = useSelector(getUserRequestState);
	const isRequestInProgress = isEqual(requestState, RequestState.Requested);
	const isUpdateDisabled = isUserInfoUpdated || isRequestInProgress;
	const [teamName, setTeamNameName] = useState(user.username);

	const clearFormState = useCallback(() => {
		dispatch(unMarkUserInfoAsUpdated());
	}, [dispatch]);

	const onSubmit = useCallback(
		(event: React.SyntheticEvent<IForm>) => {
			event.preventDefault();
			const form = event.currentTarget;

			if (!form.checkValidity()) {
				return;
			}

			dispatch(
				userInfoUpdate({
					username: form.username.value,
					opt_in: form.opt_in.checked,
				})
			);
		},
		[dispatch]
	);

	const onLogout = () => window.gigya?.accounts.logout();

	const handleInputTeamNameValue = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setTeamNameName(event.target.value.replace("@", ""));
	}, []);

	useEffect(() => () => clearFormState(), [clearFormState]);

	return (
		<Fragment>
			<PageTitle>Update details</PageTitle>
			<Wrapper>
				<p>
					If you face any issues, please click{" "}
					<InternalLink to="/help/contact">here</InternalLink> to contact our support
					team.
				</p>
				<Form onSubmit={onSubmit} onChange={clearFormState}>
					<Grid>
						<Input
							value={teamName}
							onInput={handleInputTeamNameValue}
							label="Team Name"
							name="username"
							type="text"
							placeholder="Team Name"
							inputProps={{maxLength: 40}}
							required={true}
						/>
						<RowCheckbox>
							<Checkbox name="opt_in" id="opt_in" defaultChecked={user.opt_in} />
							<StyledLabel htmlFor="opt_in">
								I would like to receive updates about Fantasy DP World Tour
							</StyledLabel>
						</RowCheckbox>
					</Grid>
					<p>
						Click{" "}
						<ExternalLink href="https://www.europeantour.com/my-tour/profile">
							here
						</ExternalLink>{" "}
						to update your My Tour account
					</p>
					<br />
					<ButtonsBox>
						<ButtonPrimaryAlt disabled={isUpdateDisabled}>
							<Exist when={!isRequestInProgress}>
								<span>{isUserInfoUpdated ? "Updated" : "Update"}</span>
							</Exist>
							<Exist when={isRequestInProgress}>
								<Preloader />
							</Exist>
						</ButtonPrimaryAlt>
						<ButtonPrimaryAlt
							disabled={isRequestInProgress}
							type="button"
							onClick={onLogout}>
							Logout
						</ButtonPrimaryAlt>
					</ButtonsBox>
				</Form>
			</Wrapper>
		</Fragment>
	);
};

export default Account;
