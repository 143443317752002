import {IStore} from "modules/types/store";
import {createSelector} from "reselect";
import {getViewedEvent} from "modules/selectors/events";
import {getPlayersListForViewedEvent} from "modules/selectors/players";
import {LINEUP_SIZE} from "modules/constants";
import {filter, get, identity, isEmpty, keyBy, merge, size} from "lodash";
import {EventStatus, RequestState} from "modules/enums";

export const getTeamState = ({team: {hasUnsavedChanges, requestState}}: IStore) => ({
	hasUnsavedChanges,
	requestState,
});

export const getSuccessTeamSaveFlag = ({team}: IStore) => team.showSuccessSaveModal;

export const getTeamsByEventID = ({team}: IStore) => team.byEvent;

export const getViewedTeam = createSelector(
	getTeamsByEventID,
	getViewedEvent,
	(teamsByEventID, event): typeof teamsByEventID[number] | null =>
		teamsByEventID[event?.id || Number.MAX_SAFE_INTEGER] || null
);

export const getViewedTeamLineup = createSelector(
	getViewedTeam,
	getPlayersListForViewedEvent,
	(team, players) => {
		const emptyLineup = new Array<null>(LINEUP_SIZE).fill(null);
		const playersByID = keyBy(players, "id");

		return merge(emptyLineup, team?.lineup).map((playerID) => playersByID[playerID!] || null);
	}
);

export const getPickedPlayersSize = createSelector(getViewedTeam, (team) =>
	size(filter(team?.lineup, identity))
);

export const getIsTeamFull = createSelector(getPickedPlayersSize, (pickedPlayersSize) => pickedPlayersSize === LINEUP_SIZE);

export const getViewedTeamPoints = createSelector(
	getViewedEvent,
	getViewedTeam,
	getViewedTeamLineup,
	(event, team, lineupPlayers) => {
		if (!team || !event) return null;
		if (event?.status === EventStatus.Scheduled) return null;

		const filteredLineup = lineupPlayers.filter((player) => Boolean(player));

		if (isEmpty(filteredLineup)) return null;

		const captainID = team?.captain;

		return filteredLineup.reduce((acc, player) => {
			const isCaptain = captainID === player.id;
			const multiplier = isCaptain ? 2 : 1;

			return acc + get(player, `stats.event_scores.${String(event?.id)}`, 0) * multiplier;
		}, 0);
	}
);

export const getModalPlayerID = ({team}: IStore) => team.showDetailsForPlayerID;
export const getLastPlayerIDCard = ({team}: IStore) => team.lastPlayerIDCard;
export const getIsTeamLoading = createSelector(getTeamState, (state) => state.requestState === RequestState.Requested);