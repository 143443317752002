import styled, {css} from "styled-components";

const adText = css`
	&:after {
		content: "";
		color: #000;
		font-size: 16px;
		display: block;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
`;

export const AdRectangle = styled.div`
	//background: #d8d8d8;
	width: 728px;
	height: 90px;
	margin: 30px auto 50px;
	position: relative;

	@media (max-width: 728px) {
		width: 320px;
		height: 40px;
	}

	${adText};
`;

export const AdSquare = styled.div`
	//background: #d8d8d8;
	width: 300px;
	//height: 250px;
	margin: 0 auto;
	position: relative;

	${adText};
`;
