import React, {Fragment, useEffect, useRef} from "react";
import {Outlet, useLocation} from "react-router-dom";
import styled from "styled-components";
import {Container as BaseContainer, ExternalLink, Main} from "components/Common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import logoLight from "assets/img/fantlogo-light.svg";
import {IS_SECRET_PASSED} from "modules/constants";
import {FooterDark} from "components/Footer";
import {Header} from "components/Header";
import {AdRectangle, AdSquare, GlobalMessage} from "components";
// import {choice, Storage} from "modules/utils";
// import {isEmpty} from "lodash";

// import bannerSide1_300x250 from "assets/img/ad/300x250/Justin-Rose_300x250_Rose_Thomas_300x250.png";
// import bannerSide2_300x250 from "assets/img/ad/300x250/Tommy-Fleetwood_300x250_Fleetwood_Fowler_300x250 (1).png";
// import bannerSide3_300x250 from "assets/img/ad/300x250/Tyrrell-Hatton_300x250_Hatton_Morikawa_300x250.png";
//
// import bannerSide1_728x90 from "assets/img/ad/728x90/Justin-Rose_728x90_Rose_Thomas_728x90.png";
// import bannerSide2_728x90 from "assets/img/ad/728x90/Tommy-Fleetwood_728x90_Fleetwood_Fowler_728x90.png";
// import bannerSide3_728x90 from "assets/img/ad/728x90/Tyrrell-Hatton_728x90_Hatton_Morikawa_728x90.png";

import squareBannerSrc from "assets/img/ad/300x250/DPW_OC_36 Banners for Fantasy 300x250px FA-20240102-111305.jpg";
import rectangleBannerSrc from "assets/img/ad/728x90/DPW_OC_36 Banners for Fantasy 728x90px FA-20240102-111305.jpg";

const Wrapper = styled.div`
	box-sizing: border-box;
	padding: 60px 0;
	text-align: center;
`;

const Container = styled(BaseContainer)`
	display: flex;
	flex-flow: column;
	align-items: center;
	
	&.full-container {
		@media (max-width: 840px) {
			padding: 0 !important;
		}
	}
`;

const Logo = styled.img`
	display: block;
	max-width: 100%;
	margin: 20px auto 50px;
	transform: translateX(-20px);
`;

const Title = styled.h1`
	max-width: 400px;
	font-size: 30px;
	margin: 0 auto 10px;
	color: #fff;
	line-height: 1.3;
`;

const BackLink = styled(ExternalLink)`
	position: absolute;
	left: 20px;
	top: 20px;
	display: inline-flex;
	align-items: center;

	span {
		display: inline-block;
		margin-left: 10px;
	}
`;

export const LandingCoreLayout: React.FC = ({children}) => {
	const ref = useRef<HTMLSpanElement>(null);

	useEffect(() => {
		const element = ref.current;

		if (element && !IS_SECRET_PASSED) {
			let count = 0;

			element.addEventListener("click", () => {
				count++;

				if (count >= 10) {
					sessionStorage.setItem("isSecretPassed", "true");
					window.location.reload();
				}
			});
		}
	}, [ref]);

	return (
		<Wrapper>
			<BackLink target="_self" href="https://www.europeantour.com">
				<FontAwesomeIcon icon="chevron-left" />
				<span>Back to DP World Tour</span>
			</BackLink>
			<Container>
				<Logo src={logoLight} alt="DP World Tour Fantasy" />
				<Title>
					DP World Tour Fantasy
					<br />
					2023 is coming <span ref={ref}>soon</span>....
				</Title>
				{children}
			</Container>
		</Wrapper>
	);
};

export const LandingLayout: React.FC = () => {
	return (
		<Fragment>
			<Main>
				<Outlet />
			</Main>
			<FooterDark />
		</Fragment>
	);
};

export const MainLayout: React.FC = () => {
	return (
		<Fragment>
			<Header />
			<GlobalMessage />
			<Main>
				<Outlet />
			</Main>
			<FooterDark />
		</Fragment>
	);
};

const LayoutContent = styled.section`
	flex: 1;
`;

const Aside = styled.aside`
	width: 300px;
`;

const TwoColumnLayout = styled.div`
	display: flex;
	flex-flow: column;
	width: 100%;

	${Container} {
		flex-flow: row;
		align-items: flex-start;
		gap: 80px;
	}

	@media (max-width: 1200px) {
		${Container} {
			gap: 20px;
		}
	}

	@media (max-width: 1000px) {
		${Aside} {
			display: none;
		}
	}
`;

const LayoutBottomSpacer = styled.div`
	height: 150px;

	@media (max-width: 728px) {
		height: 60px;
	}
`;

// const AdLink = styled.a`
// 	img {
// 		width: 100%;
// 	}
// `;
//
// const shopURL =
// 	"https://shop.europeantour.com/collections/dp-world-tour-collection?utm_source=fantasy_game&utm_medium=ads&utm_campaign=fantasy_shop";
//
// export const BannerAD: React.FC = () => (
// 	<AdRectangle>
// 		<AdLink target="_blank" href={shopURL + "_ads"}>
// 			<img src={bannerAD} alt="" role="banner" />
// 		</AdLink>
// 	</AdRectangle>
// );
//
// export const SquareAD: React.FC = () => (
// 	<AdSquare>
// 		<AdLink target="_blank" href={shopURL + "_GIF"}>
// 			<img src={sideAD} alt="" role="banner" />
// 		</AdLink>
// 	</AdSquare>
// );

const AdLink = styled.a`
	img {
		width: 100%;
	}
`;

AdLink.defaultProps = {
	target: "_blank",
	rel: "noopener noreferrer",
};

// const getRandomImage = (images: string[], localStorageKey: string) => {
// 	const excludeArray = JSON.parse(Storage.GET(localStorageKey) || "[]") as string[];
// 	const filteredImages = images.filter((value) => !excludeArray.includes(value));
// 	const isNoBannersToShow = isEmpty(filteredImages);
// 	const imageSrc = choice(isNoBannersToShow ? images : filteredImages);
//
// 	if (isNoBannersToShow) {
// 		Storage.REMOVE(localStorageKey);
// 		Storage.SET(localStorageKey, [imageSrc]);
// 	} else {
// 		Storage.SET(localStorageKey, [...excludeArray, imageSrc]);
// 	}
//
// 	return imageSrc;
// };

// const IMAGES_728x90 = [bannerSide1_728x90, bannerSide2_728x90, bannerSide3_728x90];
//
// const IMAGE_300x250 = [bannerSide1_300x250, bannerSide2_300x250, bannerSide3_300x250];

// const MIDDLE_EAST_CHALLENGE_END_DATE = DateTime.fromISO("2023-02-01");

// if (DateTime.now() <= MIDDLE_EAST_CHALLENGE_END_DATE) {
// 	IMAGES_728x90.push(bannerSide4_728x90);
// 	IMAGE_300x250.push(bannerSide4_300x250);
// }

const trackingLink =
	"https://www.dpworld.com/partnerships/golf";

export const BannerAD: React.FC = () => {
	// const imageSrc = getRandomImage(IMAGES_728x90, "728x90_exclude");

	return (
		<AdRectangle>
			<AdLink href={trackingLink}>
				<img src={rectangleBannerSrc} alt="" role="banner" />
			</AdLink>
		</AdRectangle>
	);
};

export const SquareAD: React.FC = () => {
	// const imageSrc = getRandomImage(IMAGE_300x250, "300x250_exclude");

	return (
		<AdSquare>
			<AdLink href={trackingLink}>
				<img src={squareBannerSrc} alt="" role="banner" />
			</AdLink>
		</AdSquare>
	);
};

export const ThreeAdsLayout: React.FC = () => (
	<TwoColumnLayout>
		<BannerAD />
		<Container>
			<LayoutContent>
				<Outlet />
			</LayoutContent>
			<Aside>
				<SquareAD />
			</Aside>
		</Container>
		<BannerAD />
	</TwoColumnLayout>
);

const FULL_CONTAINER_PATHS = ["/leagues"];

export const TwoAdsLayout: React.FC = () => {
	const location = useLocation();
	const className = FULL_CONTAINER_PATHS.includes(location.pathname) ? "full-container" : "";

	return (
		<TwoColumnLayout>
			<BannerAD />
			<Container className={className}>
				<LayoutContent>
					<Outlet />
				</LayoutContent>
				<Aside>
					<SquareAD />
				</Aside>
			</Container>
			<LayoutBottomSpacer />
		</TwoColumnLayout>
	)
};
