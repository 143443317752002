import {createReducer} from "redux-act";
import {
	IPlayer,
	IPlayerStatsByEvent,
	requestPlayersJSONSuccess,
	requestPlayerStatsJSONSuccess,
} from "modules/actions";

interface IReducer {
	playersByEventID: Record<number, IPlayer[]>;
	statsByPlayerID: Record<number, Record<number, IPlayerStatsByEvent>>;
}

const defaultState: IReducer = {
	playersByEventID: {},
	statsByPlayerID: {},
};

export const players = createReducer<typeof defaultState>({}, defaultState)
	.on(requestPlayersJSONSuccess, (state, {players, eventID}) => ({
		...state,
		playersByEventID: {
			...state.playersByEventID,
			[eventID]: players,
		},
	}))
	.on(requestPlayerStatsJSONSuccess, (state, {playerID, stats}) => ({
		...state,
		statsByPlayerID: {
			...state.statsByPlayerID,
			[playerID]: stats,
		},
	}));
