import {
	fetchFaqFailed,
	fetchFaqSuccess,
	fetchGameRulesFailed,
	fetchGameRulesSuccess,
	fetchMessageFailed,
	fetchMessageSuccess,
	fetchPrivacyFailed,
	fetchPrivacySuccess,
	fetchPrizesFailed,
	fetchPrizesSuccess,
	fetchScheduleFailed,
	fetchScheduleSuccess, fetchSwingsJsonSuccess,
	fetchTermsFailed,
	fetchTermsSuccess,
	requestCountriesJSONFailed,
	requestCountriesJSONSuccess,
	requestCurrentUserSuccess,
	requestEventsJSONFailed,
	requestEventsJSONSuccess,
	requestPlayersJSON,
	requestPlayersJSONFailed,
	requestPlayersJSONSuccess,
	requestPlayerStatsJSON,
	requestPlayerStatsJSONFailed,
	requestPlayerStatsJSONSuccess,
	requestSeasonStatsJSONFailed,
	requestSeasonStatsJSONSuccess,
	userLoginSuccess,
	userRegisterSuccess,
} from "modules/actions";
import {call, delay, put, race, select, take} from "typed-redux-saga";
import {Api} from "modules/utils";
import {isArray, isEmpty} from "lodash";
import {
	getCountries,
	getFaq,
	getGameRules,
	getPrizes,
	getRollbackSeasonOrUndefined,
	getPrivacy,
	getSchedule,
	getTerms,
	isLoggedIn,
} from "modules/selectors";

export const requestEventsJSONSaga = function* () {
	try {
		const season = yield* select(getRollbackSeasonOrUndefined);
		const result = yield* call(Api.JSON.events, season);

		if (isArray(result)) {
			yield* put(requestEventsJSONSuccess(result));
		}
	} catch (err) {
		yield* put(requestEventsJSONFailed());
	}
};

export const requestPlayersJSONSaga = function* ({payload}: ReturnType<typeof requestPlayersJSON>) {
	try {
		const season = yield* select(getRollbackSeasonOrUndefined);
		const result = yield* call(Api.JSON.players, payload, season);

		if (isArray(result)) {
			yield* put(
				requestPlayersJSONSuccess({
					players: result,
					eventID: payload,
				})
			);
		}
	} catch (err) {
		yield* put(requestPlayersJSONFailed());
	}
};

export const requestPlayerStatsJSONSaga = function* ({
	payload,
}: ReturnType<typeof requestPlayerStatsJSON>) {
	try {
		const result = yield* call(Api.JSON.playerStats, payload);

		yield* put(
			requestPlayerStatsJSONSuccess({
				stats: result,
				playerID: payload,
			})
		);
	} catch (err) {
		yield* put(requestPlayerStatsJSONFailed());
	}
};

export const requestCountriesJSONSaga = function* () {
	try {
		const value = yield* select(getCountries);

		if (!isEmpty(value)) {
			yield* put(requestCountriesJSONSuccess(value));
			return;
		}

		const result = yield* call(Api.JSON.countries);

		if (isArray(result)) {
			yield* put(requestCountriesJSONSuccess(result));
		}
	} catch (err) {
		yield* put(requestCountriesJSONFailed());
	}
};

export const requestTermsJSONSaga = function* () {
	try {
		const {value} = yield* select(getTerms);

		if (value) {
			yield* put(fetchTermsSuccess(value));
			return;
		}

		const result = yield* call(Api.JSON.terms);

		yield* put(fetchTermsSuccess(result));
	} catch (err) {
		yield* put(fetchTermsFailed());
	}
};

export const requestScheduleJSONSaga = function* () {
	try {
		const {value} = yield* select(getSchedule);

		if (value) {
			yield* put(fetchScheduleSuccess(value));
			return;
		}

		const result = yield* call(Api.JSON.schedule);

		yield* put(fetchScheduleSuccess(result));
	} catch (err) {
		yield* put(fetchScheduleFailed());
	}
};

export const requestGameRulesJSONSaga = function* () {
	try {
		const {value} = yield* select(getGameRules);

		if (value) {
			yield* put(fetchGameRulesSuccess(value));
			return;
		}

		const result = yield* call(Api.JSON.gameRules);

		yield* put(fetchGameRulesSuccess(result));
	} catch (err) {
		yield* put(fetchGameRulesFailed());
	}
};

export const requestFaqJSONSaga = function* () {
	try {
		const {value} = yield* select(getFaq);

		if (value) {
			yield* put(fetchFaqSuccess(value));
			return;
		}

		const result = yield* call(Api.JSON.faq);

		yield* put(fetchFaqSuccess(result));
	} catch (err) {
		yield* put(fetchFaqFailed());
	}
};

export const requestPrizesJSONSaga = function* () {
	try {
		const {value} = yield* select(getPrizes);

		if (value) {
			yield* put(fetchPrizesSuccess(value));
			return;
		}

		const result = yield* call(Api.JSON.prizes);

		yield* put(fetchPrizesSuccess(result));
	} catch (err) {
		yield* put(fetchPrizesFailed());
	}
};

export const requestPrivacyJSONSaga = function* () {
	try {
		const {value} = yield* select(getPrivacy);

		if (value) {
			yield* put(fetchPrivacySuccess(value));
			return;
		}

		const result = yield* call(Api.JSON.privacy);

		yield* put(fetchPrivacySuccess(result));
	} catch (err) {
		yield* put(fetchPrivacyFailed());
	}
};

const FIVE_MINUTES = 1000 * 60 * 5;

export const pollingMessageJSONSaga = function* () {
	if (!(yield* select(isLoggedIn))) {
		yield* race([
			take(userRegisterSuccess),
			take(userLoginSuccess),
			take(requestCurrentUserSuccess),
		]);
	}

	while (true) {
		if (yield* select(isLoggedIn)) {
			try {
				const result = yield* call(Api.JSON.message);
				yield* put(fetchMessageSuccess(result));
			} catch (err) {
				yield* put(fetchMessageFailed());
			}
		}

		yield* delay(FIVE_MINUTES);
	}
};

export const fetchSwingsSaga = function* () {
	try {
		const result = yield* call(Api.JSON.swings);

		if (isArray(result)) {
			yield* put(fetchSwingsJsonSuccess(result));
		}
	} catch (err) {
		console.log(err);
	}
};

export const requestSeasonStatsJSONSaga = function* () {
	try {
		const result = yield* call(Api.JSON.seasonStats);
		yield* put(requestSeasonStatsJSONSuccess(result));
	} catch (err) {
		yield* put(requestSeasonStatsJSONFailed());
	}
};