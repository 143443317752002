import React, {Fragment, useCallback} from "react";
import {
	ButtonsBox,
	CloseButton,
	Modal,
	ModalContent,
	ModalInner,
	ModalLogo,
	ModalText,
	ModalTitle,
	Overlay,
} from "components/Modals/common";
import iconWarning from "assets/img/icon-warning.svg";
import {ButtonSecondary} from "components/Buttons";
import {useDispatch, useSelector} from "react-redux";
import {getPlayerByID, getViewedEvent, getViewedTeam} from "modules/selectors";
import {identity, size} from "lodash";
import {EventStatus} from "modules";
import {clearWithdrawn, IPlayer} from "modules/actions";

export const WithdrawnPlayersModal: React.FC = () => {
	const dispatch = useDispatch();
	const event = useSelector(getViewedEvent);
	const team = useSelector(getViewedTeam);
	const getPlayerByIDSelector = useSelector(getPlayerByID);
	const eventID = event?.id;
	const eventStarted = [EventStatus.Active, EventStatus.Complete].some(
		(status) => status === event?.status
	);
	const withdrawn = team?.withdrawn || [];
	const withdrawnPlayersAmount = size(withdrawn);
	const isSingleReplace = withdrawnPlayersAmount < 2;
	const replacedPlayers = withdrawn
		.map((playerID) => getPlayerByIDSelector(playerID))
		.filter(identity) as IPlayer[];
	const isModalVisible = Boolean(withdrawnPlayersAmount) && eventStarted;

	const handleClose = useCallback(() => {
		if (eventID) {
			dispatch(clearWithdrawn(eventID));
		}
	}, [dispatch, eventID]);

	if (!isModalVisible) {
		return null;
	}

	const title = isSingleReplace
		? "We have replaced a player in your team"
		: `We have replaced ${withdrawnPlayersAmount} players in your team`;

	const eventName = event?.name || "";
	const content = [
		`The following golfer${
			isSingleReplace ? "" : "s"
		} in your team have withdrawn from the ${eventName}: `,
		replacedPlayers.map(({firstname, lastname}) => `${firstname} ${lastname}`).join(", ") +
			". ",
		"We have assigned an automatic replacement for you, so you don’t miss out on points.",
	].join("");

	return (
		<Fragment>
			<Overlay onClick={handleClose} />
			<Modal>
				<ModalInner>
					<CloseButton onClick={handleClose} />
					<ModalContent>
						<ModalLogo src={iconWarning} alt="" role="banner" />
						<ModalTitle>{title}</ModalTitle>
						<ModalText>{content}</ModalText>
						<ButtonsBox>
							<ButtonSecondary onClick={handleClose}>Close</ButtonSecondary>
						</ButtonsBox>
					</ModalContent>
				</ModalInner>
			</Modal>
		</Fragment>
	);
};
