import {createReducer} from "redux-act";
import {
	openTutorial,
	closeTutorial,
	setActiveStep,
	setIsOpenMobileNavigation,
} from "modules/actions";

export interface ITutorial {
	isOpen: boolean;
	step: number;
	isOpenMobileNavigation: boolean;
}

const initialState: ITutorial = {
	isOpen: false,
	step: 0,
	isOpenMobileNavigation: false,
};

const onOpenTutorial = (state: ITutorial) => ({
	...state,
	isOpen: true,
	step: 0,
	isOpenMobileNavigation: false,
});

const onCloseTutorial = (state: ITutorial) => ({
	...state,
	isOpen: false,
	isOpenMobileNavigation: false,
});

const onSetActiveStep = (state: ITutorial, payload: number) => ({
	...state,
	step: payload,
});

const onSetIsOpenMobileNavigation = (state: ITutorial, payload: boolean) => ({
	...state,
	isOpenMobileNavigation: payload,
});

export const tutorial = createReducer({}, initialState)
	.on(openTutorial, onOpenTutorial)
	.on(closeTutorial, onCloseTutorial)
	.on(setActiveStep, onSetActiveStep)
	.on(setIsOpenMobileNavigation, onSetIsOpenMobileNavigation);
