import {createAction} from "redux-act";

export interface IMessageResponse {
	id: number;
	type: string;
	title: string;
	text: string;
}

export const fetchMessageSuccess = createAction<IMessageResponse>();
export const fetchMessageFailed = createAction();
