import {all, call, delay, race, SagaGenerator, select, take} from "typed-redux-saga";
import {requestMyTeam, requestPlayersJSON, unsubscribeFromLiveScores} from "modules/actions";
import {requestEventsJSONSaga, requestPlayersJSONSaga} from "modules/sagas/json";
import {getViewedEvent} from "modules/selectors";
import {showMyTeamSaga} from "modules/sagas/team";

const SEC = 1000;
const MIN = SEC * 60;
const WAIT = MIN * 20;

export const fetchLiveScoresSaga = function* (): SagaGenerator<void> {
	const {stopped} = yield* race({
		wait: delay(WAIT),
		stopped: take(unsubscribeFromLiveScores),
	});

	if (!stopped) {
		const event = yield* select(getViewedEvent);
		const eventID = event?.id;

		if (eventID) {
			yield* all([
				call(showMyTeamSaga, requestMyTeam(eventID)),
				call(requestPlayersJSONSaga, requestPlayersJSON(eventID)),
				call(requestEventsJSONSaga),
			]);
		}

		yield* call(fetchLiveScoresSaga);
	}
};
