import {createSelector} from "reselect";
import {IStore} from "modules/types/store";
import {RequestState} from "modules/enums";

export const getUserState = ({user}: IStore) => user;

export const isSessionChecked = createSelector(
	getUserState,
	({sessionCheckedState}) => sessionCheckedState === RequestState.Received
);

export const isAdditionalDataRequired = createSelector(getUserState, (state) =>
	Boolean(state.isAdditionalDataRequired)
);

export const getGigyaCredentials = createSelector(getUserState, (state) => state.gigya);

export const getUser = createSelector(getUserState, ({user}) => user);
export const getUserRequestState = createSelector(getUserState, ({requestState}) => requestState);
export const getUserError = createSelector(getUserState, ({error}) => error);
export const isLoggedIn = createSelector(getUser, (user) => Boolean(user));
export const isLogOutWasCalled = createSelector(
	getUserState,
	({logoutWasCalled}) => logoutWasCalled
);

export const getResetPasswordState = createSelector(
	getUserState,
	({passwordReset}) => passwordReset
);

export const getUserInfoUpdated = createSelector(
	getUserState,
	({userInfoUpdated}) => userInfoUpdated
);

export const getUserPasswordUpdated = createSelector(
	getUserState,
	({passwordUpdated}) => passwordUpdated
);
