import {IStore} from "modules/types/store";
import {createSelector} from "reselect";
import {keyBy} from "lodash";
import {SEASONS_MONTHS} from "modules/constants";

export const getLeaguesState = ({leagues}: IStore) => leagues;

export const getTempCreatedLeague = createSelector(
    getLeaguesState,
    ({tempCreatedLeague}) => tempCreatedLeague
);

export const getLeagueInvitesRequestState = createSelector(
    getLeaguesState,
    ({leagueInvitesRequestState}) => leagueInvitesRequestState
);

export const getMyLeagues = createSelector(getLeaguesState, ({myLeagues}) => myLeagues);
export const getInactiveLeagues = createSelector(getLeaguesState, ({myLeagues}) => myLeagues.inactiveLeagues);

export const getLeaguesForJoin = createSelector(
    getLeaguesState,
    ({leaguesForJoin}) => leaguesForJoin
);

export const getLeagueRankings = createSelector(
    getLeaguesState,
    ({leagueRankings}) => leagueRankings
);

export const getIsUserRowInRankings = createSelector(getLeagueRankings, (entity) =>
    entity.ranking.some((e) => e.user_id === entity.user?.user_id)
)

export const getLeagueUsersList = createSelector(
    getLeaguesState,
    ({leagueUsersList}) => leagueUsersList
);

export const getMyLeaguesByID = createSelector(getMyLeagues, (leagues) =>
    keyBy(leagues.list, "id")
);

export const getLeagueByID = createSelector(
    getMyLeaguesByID,
    (leaguesById) =>
        (leagueID: number): typeof leaguesById[number] | undefined =>
            leaguesById[leagueID]
);

export const getIsJoinedToLeagueSelector = createSelector(
    getLeaguesState,
    ({joinedLeaguesFlags}) =>
        (leagueID: number) =>
            joinedLeaguesFlags[leagueID]
);

export const getRankingsMonths = createSelector(getLeaguesState, (state) => {
    return state.standingMonths.map((item) => {
        const month = SEASONS_MONTHS.find((e) => e.id === item.month);
        const customId = `${item.year}-${item.month}`;

        return {...item, name: month?.name || "", customId};
    })
});

export const getSelectedEventForLeaderboard = createSelector(getLeaguesState, (state) => state.rankingsSelectedEvent)