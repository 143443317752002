import {createAction} from "redux-act";

export interface IHelpResponse {
	id: number;
	type: string;
	title: string;
	text: string;
}

export interface IFaqItem {
	title: string;
	text: string;
}

export const fetchTerms = createAction();
export const fetchTermsSuccess = createAction<IHelpResponse>();
export const fetchTermsFailed = createAction();

export const fetchSchedule = createAction();
export const fetchScheduleSuccess = createAction<IHelpResponse>();
export const fetchScheduleFailed = createAction();

export const fetchGameRules = createAction();
export const fetchGameRulesSuccess = createAction<IHelpResponse>();
export const fetchGameRulesFailed = createAction();

export const fetchFaq = createAction();
export const fetchFaqSuccess = createAction<IFaqItem[]>();
export const fetchFaqFailed = createAction();

export const fetchPrizes = createAction();
export const fetchPrizesSuccess = createAction<IHelpResponse>();
export const fetchPrizesFailed = createAction();

export const fetchPrivacy = createAction();
export const fetchPrivacySuccess = createAction<IHelpResponse>();
export const fetchPrivacyFailed = createAction();
