import {IStore} from "modules/types/store";
import {createSelector} from "reselect";

export const getHelpState = (store: IStore) => store.help;

export const getFaq = createSelector(getHelpState, ({faq}) => faq);
export const getTerms = createSelector(getHelpState, ({terms}) => terms);
export const getSchedule = createSelector(getHelpState, ({schedule}) => schedule);
export const getPrizes = createSelector(getHelpState, ({prizes}) => prizes);
export const getGameRules = createSelector(getHelpState, ({gameRules}) => gameRules);
export const getPrivacy = createSelector(getHelpState, ({privacy}) => privacy);
