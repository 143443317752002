import {createReducer} from "redux-act";
import {fetchMessageSuccess} from "modules/actions";

interface IMessagesReducer {
	text?: string;
}

export const messages = createReducer<IMessagesReducer>({}, {}).on(
	fetchMessageSuccess,
	(state, {text}) => ({text})
);
