import {
	IUserLoginPayload,
	askUserToCompleteRegistration,
	markUserInfoAsUpdated,
	requestCurrentUserFailed,
	requestCurrentUserSuccess,
	requestResetPassword,
	requestResetPasswordFailed,
	requestResetPasswordSuccess,
	resetPassword,
	resetPasswordFailed,
	resetPasswordSuccess,
	showGlobalError,
	userInfoUpdate,
	userLogin,
	userLoginFailed,
	userLoginSuccess,
	userLogoutFailed,
	userLogoutSuccess,
	userRegister,
	userRegisterFailed,
	userRegisterSuccess,
	userUpdate,
	showAcceptTermsModal,
	userAcceptTermsSuccess,
	userAcceptTermsFailed,
	userAcceptTerms,
} from "modules/actions";
import {Api, ApiError, createConnextraScriptTag, trackUser} from "modules/utils";
import {call, put, race, select, take} from "typed-redux-saga";
import {get, isEqual} from "lodash";
import {GAME_TYPE, IS_SECRET_PASSED} from "modules/constants";
import {getUser} from "modules/selectors";
import { getIsUserRegisteredForGame } from "modules/utils/User";

export const loginSaga = function* ({payload}: ReturnType<typeof userLogin>) {
	try {
		const {uid, uidSignature, signatureTimestamp} = payload;
		const {
			success: {user},
		} = yield* call(Api.Auth.login, {uid, uidSignature, signatureTimestamp});

		void trackUser({
			event: "login",
			user_details: {
				user_id: uid,
				user_emid: user.email,
			}
		});

		if (!getIsUserRegisteredForGame(user, GAME_TYPE)) {
			yield* put(showAcceptTermsModal());

			yield* race([take(userAcceptTermsSuccess), take(userAcceptTermsFailed)]);

			const user = yield* select(getUser);

			if (user) {
				yield* put(userLoginSuccess(user));
			}
		} else {
			yield* put(userLoginSuccess(user));
		}
	} catch (err) {
		const USER_NOT_REGISTERED = isEqual(get(err, "code"), 418);

		if (USER_NOT_REGISTERED) {
			yield put(askUserToCompleteRegistration(payload));
		} else {
			window.gigya?.accounts.logout();

			const error = err as ApiError;
			yield* put(userLoginFailed(error));
			yield* put(showGlobalError(error));
		}
	}
};

export const acceptTermsSaga = function* ({payload}: ReturnType<typeof userAcceptTerms>) {
	try {
		const {
			success: {user},
		} = yield* call(Api.Auth.registerForGame, payload);

		yield* put(userAcceptTermsSuccess(user));
	} catch (err) {
		window.gigya?.accounts.logout();

		const error = err as ApiError;
		yield* put(userAcceptTermsFailed(error));
		yield* put(showGlobalError(error));
	}
};

const getNewGigyaCredentials = () =>
	new Promise<IUserLoginPayload>((resolve, reject) => {
		window.gigya?.accounts.getAccountInfo({
			include: "id_token,profile,data",
			callback: (resp) => {
				if (resp.errorCode) {
					return reject(new ApiError(resp.errorMessage, resp.errorCode));
				}

				resolve({
					uid: resp.UID,
					uidSignature: resp.UIDSignature,
					signatureTimestamp: resp.signatureTimestamp,
					country: resp.data.address?.country,
				});
			},
		});
	});

export const registerSaga = function* ({payload}: ReturnType<typeof userRegister>) {
	try {
		const gigya = yield* call(getNewGigyaCredentials);

		const response = yield* call(Api.Auth.register, {
			...payload,
			...gigya,
		});

		const user = response.success.user;

		void trackUser({
			event: "sign_up",
			user_details: {
				user_id: gigya.uid,
				user_emid: user.email,
			}
		});
		createConnextraScriptTag(
			`https://zz.connextra.com/dcs/tagController/tag/a61b00e171af/regconfirm?AccountID=${user.id}`
		);

		yield* put(userRegisterSuccess(user));
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(userRegisterFailed(err));
		}
	}
};

export const updateUserSaga = function* ({payload}: ReturnType<typeof userUpdate>) {
	try {
		const response = yield* call(Api.User.update, payload);
		yield* put(userRegisterSuccess(response.success.user));
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(showGlobalError(err));
		}
	}
};

export const updateUserDetailsSaga = function* ({payload}: ReturnType<typeof userInfoUpdate>) {
	yield* call(updateUserSaga, userUpdate(payload));
	yield* put(markUserInfoAsUpdated());
};

export const logoutSaga = function* () {
	try {
		yield* call(Api.Auth.logout);
		yield* put(userLogoutSuccess());
		// Fix to restore scroll if a user has logged out from mobile menu
		document.body.style.overflow = "initial";
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(userLogoutFailed(err));
			yield* put(showGlobalError(err));
		}
	}
};

export const getCurrentUserSaga = function* () {
	try {
		const {
			success: {user},
		} = yield* call(Api.User.user);

		if (IS_SECRET_PASSED && !getIsUserRegisteredForGame(user, GAME_TYPE)) {
			yield* put(showAcceptTermsModal());

			yield* race([take(userAcceptTermsSuccess), take(userAcceptTermsFailed)]);

			const user = yield* select(getUser);

			if (user) {
				yield* put(requestCurrentUserSuccess(user));
			}
		} else {
			yield* put(requestCurrentUserSuccess(user));
		}
	} catch (err) {
		yield* put(requestCurrentUserFailed());
	}
};

export const requestResetPasswordSaga = function* ({
	payload,
}: ReturnType<typeof requestResetPassword>) {
	try {
		yield* call(Api.Auth.requestPasswordReset, payload);
		yield* put(requestResetPasswordSuccess());
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(requestResetPasswordFailed(err));
		}
	}
};

export const resetPasswordSaga = function* ({payload}: ReturnType<typeof resetPassword>) {
	try {
		yield* call(Api.Auth.passwordReset, payload);
		yield* put(resetPasswordSuccess());
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(resetPasswordFailed(err));
		}
	}
};
