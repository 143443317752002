import {IStore} from "modules/types/store";
import {createSelector} from "reselect";
import {getViewedEvent} from "modules/selectors/events";
import {isString, keyBy, mapValues} from "lodash";

export const getPlayersByEventMap = ({players}: IStore) => players.playersByEventID;
export const getEventsStatsOfPlayers = ({players}: IStore) => players.statsByPlayerID;

export const getPlayersListByEventID = createSelector(
	getPlayersByEventMap,
	(playersByEvent) =>
		(eventID = Number.MAX_SAFE_INTEGER) =>
			playersByEvent[eventID] || []
);

export const getPlayersListForViewedEvent = createSelector(
	getPlayersListByEventID,
	getViewedEvent,
	(getPlayersByEventID, event) => getPlayersByEventID(event?.id)
);

export const getViewedEventPlayersByID = createSelector(getPlayersListForViewedEvent, (players) =>
	keyBy(players, "id")
);

export const getPlayerByID = createSelector(
	getViewedEventPlayersByID,
	(playersByID) =>
		(playerID: number | null): typeof playersByID[number] | null =>
			playersByID[String(playerID)]
);

export const getPlayerScoreStr = (score?: number | null) => {
	if (score === 0) return "PAR";
	return score && score > 0 ? `+${score}` : score;
};

export const getEventsStatsOfPlayerByPlayerID = createSelector(
	getEventsStatsOfPlayers,
	(statsByPlayerID) => (playerID?: number) => {
		const statsByEventID = statsByPlayerID[Number(playerID)];

		if (!statsByEventID) {
			return null;
		}

		return mapValues(statsByEventID, (eventStats) => {
			if (!eventStats.position) {
				return {
					...eventStats,
					scoreStr: getPlayerScoreStr(eventStats.score),
					finalPosition: null,
				};
			}

			const {position_desc, is_tie, position, missed_cut} = eventStats.position;
			const positionStr = `${is_tie ? "T" : ""}${position ?? "-"}`;
			const positionValue = isString(position_desc) ? position_desc : positionStr;
			const finalPosition = missed_cut ? "MC" : positionValue;

			return {
				...eventStats,
				finalPosition,
				scoreStr: getPlayerScoreStr(eventStats.score),
			};
		});
	}
);
