import React, {useCallback} from "react";
import {ModalWindow} from "components/Modals/common";
import {useDispatch, useSelector} from "react-redux";
import {clearGlobalError} from "modules/actions";
import {getErrorModalMessage} from "modules/selectors";
import iconWarning from "assets/img/icon-warning.svg";

export const ErrorModal: React.FC = () => {
	const dispatch = useDispatch();
	const errorMessage = useSelector(getErrorModalMessage);

	const handleClose = useCallback(() => {
		dispatch(clearGlobalError());
	}, [dispatch]);

	return (
		<ModalWindow
			title="Error"
			onClose={handleClose}
			message={errorMessage}
			iconSrc={iconWarning}
		/>
	);
};
