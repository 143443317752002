import "assets/css/reset.css";
import "assets/css/fonts.css";
import "assets/css/core.css";
import "assets/css/gigya.css";

import React, {lazy, Suspense} from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import reportWebVitals from "./reportWebVitals";
import {BASE_URL, IS_LEAGUE_MANAGE_DISABLED, IS_SECRET_PASSED} from "modules";
import {Provider} from "react-redux";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {
    ErrorModal,
    Exist,
    GigyaGate,
    LandingLayout,
    MainLayout,
    NotAuthOnlyRoute,
    OneTrust,
    PagePreloader,
    PrivateRoute,
    ThreeAdsLayout,
    TwoAdsLayout,
} from "components";
import {store} from "modules/store";
import {retryFailLoad} from "modules/utils";
import {TermsModal} from "components/Modals/TermsModal";
import {SeasonToggleModal} from "components/Modals/SeasonToggleModal";

Sentry.init({
    dsn: "https://ad7ac53d671f4a3c9a9c9dd89245c6d2@o151969.ingest.sentry.io/6132465",
    environment: process.env.REACT_APP_SENTRY_ENV || "development",
    sampleRate: 0.1,
    tracesSampleRate: 0.1,
    allowUrls: [".f2p.media.geniussports.com", "fantasy.dpworldtour.com"],
    integrations: [
        new Integrations.BrowserTracing(),
        new Sentry.Integrations.Breadcrumbs({
            console: false,
        }),
    ],
    denyUrls: [
        "quantcast",
        "xsca",
        // browser's extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^moz-extension:\/\//i,
    ],
    autoSessionTracking: true,
    ignoreErrors: [
        "Non-Error promise rejection captured with value: Object Not Found"
    ],
});

const Login = lazy(retryFailLoad(() => import("pages/Login")));
const NotFound = lazy(retryFailLoad(() => import("pages/NotFound")));
const Team = lazy(retryFailLoad(() => import("pages/Team")));
const RivalTeam = lazy(retryFailLoad(() => import("pages/team/RivalTeam")));
const Leaderboard = lazy(retryFailLoad(() => import("pages/Leaderboard")));
const Help = lazy(retryFailLoad(() => import("pages/Help")));
const Prizes = lazy(retryFailLoad(() => import("pages/Prizes")));
const Cookies = lazy(retryFailLoad(() => import("pages/Cookies")));
const Leagues = lazy(retryFailLoad(() => import("pages/Leagues")));
const Account = lazy(retryFailLoad(() => import("pages/Account")));
const CreateLeague = lazy(retryFailLoad(() => import("pages/leagues/CreateLeague")));
const JoinLeague = lazy(retryFailLoad(() => import("pages/leagues/JoinLeagues")));
const LeagueCore = lazy(retryFailLoad(() => import("pages/leagues/league/LeagueCore")));
const LeagueInvites = lazy(retryFailLoad(() => import("pages/leagues/league/LeagueInvites")));
const LeagueTable = lazy(retryFailLoad(() => import("pages/leagues/league/LeagueTable")));
const LeagueManage = lazy(retryFailLoad(() => import("pages/leagues/league/LeagueManage")));
const LeagueSettings = lazy(retryFailLoad(() => import("pages/leagues/league/LeagueSettings")));
const LeagueAbout = lazy(retryFailLoad(() => import("pages/leagues/league/LeagueAbout")));

const GameRoutes: React.FC = () => (
    <Routes>
        <Route element={<OneTrust />}>
            <Route element={<NotAuthOnlyRoute/>}>
                <Route element={<LandingLayout/>}>
                    <Route path="/" element={<Login/>}/>
                </Route>
            </Route>
            <Route path="/*" element={<MainLayout/>}>
                <Route element={<PrivateRoute/>}>
                    <Route path="team">
                        <Route element={<Team/>} index/>
                        <Route path=":userID/:eventID" element={<RivalTeam/>}/>
                    </Route>
                    <Route path="leagues">
                        <Route element={<TwoAdsLayout/>}>
                            <Route index element={<Leagues/>}/>
                            <Route path="join" element={<JoinLeague/>}/>
                            <Route path="create" element={<CreateLeague/>}/>
                            <Route path=":id" element={<LeagueCore/>}>
                                <Route path="table" element={<LeagueTable/>}/>
                                <Route path="invites" element={<LeagueInvites/>}/>
                                {IS_LEAGUE_MANAGE_DISABLED ? null : (
                                    <Route path="manage" element={<LeagueManage/>}/>
                                )}
                                <Route path="settings" element={<LeagueSettings/>}/>
                                <Route path="about" element={<LeagueAbout/>}/>
                            </Route>
                        </Route>
                    </Route>
                    <Route element={<TwoAdsLayout/>}>
                        <Route path="leaderboard">
                            <Route index element={<Leaderboard/>}/>
                            <Route path=":type" element={<Leaderboard/>}/>
                        </Route>
                        <Route path="account" element={<Account/>}/>
                    </Route>
                </Route>
                <Route element={<ThreeAdsLayout/>}>
                    <Route path="prizes" element={<Prizes/>}/>
                    <Route path="cookies" element={<Cookies/>}/>
                    <Route path="help/*" element={<Help/>}/>
                </Route>
                <Route path="*" element={<NotFound/>}/>
            </Route>
        </Route>
    </Routes>
);

const SecretGateRoutes: React.FC = () => (
    <Routes>
        <Route element={<LandingLayout/>}>
            <Route path="*" element={<Login/>}/>
        </Route>
    </Routes>
);

ReactDOM.render(
    <React.StrictMode>
        {/*
			Disable "Provider' cannot be used as a JSX component." error,
			as it's something wrong with @types/react version.
			More details here https://github.com/facebook/react/issues/24304
			It doesn't affect work, but breaks type checking for some elements.
		*/}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Provider store={store}>
            <Router basename={BASE_URL}>
                <GigyaGate>
                    <Suspense fallback={<PagePreloader/>}>
                        {IS_SECRET_PASSED ? <GameRoutes/> : <SecretGateRoutes/>}
                    </Suspense>
                </GigyaGate>
                <ErrorModal/>
                <Exist when={IS_SECRET_PASSED}>
                    <TermsModal/>
                    <SeasonToggleModal/>
                </Exist>
            </Router>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
void reportWebVitals();
