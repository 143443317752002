import {createReducer} from "redux-act";
import {ICountry, requestCountriesJSONSuccess} from "modules/actions";
import {sortBy} from "lodash";

const defaultState: ICountry[] = [];

export const countries = createReducer<typeof defaultState>({}, defaultState).on(
	requestCountriesJSONSuccess,
	(_, countries) => sortBy(countries, "name")
);
