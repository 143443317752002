import {IStore} from "modules/types/store";
import {createSelector} from "reselect";

export const getTutorialState = (state: IStore) => state.tutorial;
export const getIsTutorialOpen = createSelector(getTutorialState, ({isOpen}) => isOpen);
export const getTutorialStep = createSelector(getTutorialState, ({step}) => step);
export const getIsOpenMobileNavigation = createSelector(
	getTutorialState,
	({isOpenMobileNavigation}) => isOpenMobileNavigation
);
