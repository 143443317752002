import React, {useEffect} from "react";
import {useLocation} from "react-router";
import {Outlet} from "react-router-dom";

export const OneTrust: React.FC = () => {
	const location = useLocation();

	useEffect(() => {
        if (window.reloadOTBanner) {
            window.reloadOTBanner();
        }
	}, [location]);

	return <Outlet />;
};