import React, {Fragment} from "react";
import {CloseButton, Modal, ModalInner, Overlay} from "components/Modals/common";

interface IBaseModal {
	handlerClose: () => void;
}

export const BaseModal: React.FC<IBaseModal> = ({children, handlerClose}) => {
	return (
		<Fragment>
			<Overlay onClick={handlerClose} />
			<Modal>
				<ModalInner>
					<CloseButton onClick={handlerClose} />
					{children}
				</ModalInner>
			</Modal>
		</Fragment>
	);
};
