import React from "react";
import styled from "styled-components";
import {TooltipRenderProps} from "react-joyride";
import {ButtonPrimaryAlt} from "components";
import {Close} from "@mui/icons-material";
import {IconButton} from "@mui/material";

const CloseButton = styled(IconButton)`
	&.MuiIconButton-root {
		position: absolute;
		top: 8px;
		right: 8px;
	}
`;

const TooltipBody = styled.div`
	box-sizing: border-box;
	max-width: 340px;
	position: relative;
	padding: 35px 20px 20px;
	color: var(--primary1);
	background-color: #fff;
	text-align: center;
	width: 100%;

	@media (max-width: 440px) {
		max-width: none;
		width: calc(100vw - 40px);
	}
`;

const TooltipControls = styled.div`
	padding-top: 15px;
	justify-content: space-between;
	display: flex;
	align-items: center;

	button {
		margin: 0 10px;
		width: 100%;
	}
`;

const TooltipDots = styled.div`
	margin: 34px auto 0;
	width: 84px;
	display: flex;
	justify-content: space-between;
`;

const TooltipDot = styled.span<{isActive: boolean}>`
	box-sizing: border-box;
	height: 10px;
	width: 10px;
	border-radius: 50%;
	background: ${({isActive}) => (isActive ? "#6563CF" : "#E5E5E5")};
`;

export const TourTooltip: React.FC<TooltipRenderProps> = ({
	continuous,
	index,
	isLastStep,
	size,
	step,
	backProps,
	closeProps,
	skipProps,
	primaryProps,
	tooltipProps,
}) => {
	return (
		<TooltipBody {...tooltipProps}>
			<CloseButton {...skipProps}>
				<Close />
			</CloseButton>
			{step.content}
			<TooltipDots>
				{Array.from({length: 6}).map((_, i) => (
					<TooltipDot key={i} isActive={index === i} />
				))}
			</TooltipDots>
			<TooltipControls>
				<ButtonPrimaryAlt {...backProps} disabled={index <= 0}>
					Previous
				</ButtonPrimaryAlt>
				<ButtonPrimaryAlt {...primaryProps}>
					{isLastStep ? "Exit Tutorial" : "Next"}
				</ButtonPrimaryAlt>
			</TooltipControls>
		</TooltipBody>
	);
};
