import {createReducer} from "redux-act";
import {fetchSwingsJsonSuccess} from "modules/actions";

export interface ISwingEvent {
    dateEnd: string;
    dateStart: string;
    id: number;
    name: string;
    status: string;
}

export interface ISwing {
    events: ISwingEvent[];
    id: number;
    name: string
}

interface IReducer {
    swings: ISwing[];
}

const defaultState: IReducer = {
    swings: []
};

const onFetchSwingsJsonSuccess = (state: IReducer, payload: ISwing[]): IReducer => ({
    ...state,
    swings: payload
})

export const swing = createReducer<typeof defaultState>({}, defaultState)
    .on(fetchSwingsJsonSuccess, onFetchSwingsJsonSuccess)
